<template>
  <div>
    <b-sidebar
      id="sidebar-right-incomplete-crawl"
      ref="sidebar_incomplete_crawl"
      :visible="showOpenIncompleteCrawl"
      bg-variant="white"
      style="z-index: 99999;"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-incomplete-crawl', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body mt-1"
          style="padding: 20px;"
        >
          <h3>Crawl Limit Reached</h3>

          <p>You’ve reached the current limit of <b>100 unique pages</b> for a crawl. To continue crawling more pages and access additional features, you’ll need to upgrade your plan.</p>
          <p>Upgrade Now to increase your crawl limit!</p>

          <b-button
            variant="primary"
            type="submit"
            class="mr-1 mt-1 float-right btn-upgrade-license"
            @click.prevent="getLicense()"
          >
          Upgrade
          </b-button>
        </div>
        
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenIncompleteCrawl',
    event: 'update:show-open-incomplete-crawl',
  },
  props: ['idsite', 'showOpenIncompleteCrawl', 'crawls'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
    }
  },
  watch: {
    showOpenIncompleteCrawl(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_incomplete_crawl.hide()
    },
    getLicense() {
      // localStorage.setItem('actionOpenLicense', true)
      localStorage.setItem('jsonInfoLic', JSON.stringify(this.idsite))
      this.$refs.sidebar_incomplete_crawl.hide()
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.btn-upgrade-license:hover {
  border: 1px solid #7838ff !important;
  background: #bb9aff !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-incomplete-crawl {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
