<!-- MyTextArea.vue -->
<template>
    <!-- <b-form-textarea
      class="mt-75"
      style="border-style: dashed; overflow-y: hidden;"
      :placeholder="placeholder"
      rows="1"
      max-rows="17"
      v-model="internalValue"
      v-if="placeholder === 'Grammar suggest'"
    /> -->
    <textarea
      ref="textarea"
      class="mt-75"
      style="overflow-y: hidden; resize: none; border-radius: 0.357rem; width: 100%; padding: 0.8rem 30px 0.8rem 1rem !important; min-height: 45px; border-style: dashed; background: #fff;"
      :placeholder="placeholder"
      rows="1"
      v-model="internalValue"
      @input="adjustHeight"
      :disabled="true"
      v-if="placeholder === 'Grammar suggest'"
    ></textarea>
    <textarea
      ref="textarea"
      class="mt-75"
      style="overflow-y: hidden; resize: none; border-radius: 0.357rem; width: 100%; padding: 0.8rem 50px 0.8rem 1rem !important; border: 0px !important; min-height: 45px;"
      :placeholder="placeholder"
      rows="1"
      v-model="internalValue"
      @input="adjustHeight"
      v-else
    ></textarea>
</template>

<script>
import { BTabs, BTab, BCardText, BOverlay, BListGroup, BListGroupItem, BButton, BImgLazy, BFormTextarea, } from 'bootstrap-vue'

export default {
    // props: ['placeholder', 'model'],
    components: {
        BFormTextarea,
    },
    props: {
    value: { // Vue 2 utiliza `value` por defecto con `v-model`
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: this.value // Sincroniza el valor inicial con la prop `value`
    };
  },
  watch: {
    internalValue(newValue) {
      this.debouncedEmit(newValue); // Emite el valor con debounce al cambiar `internalValue`
      this.$nextTick(() => this.adjustHeight());
    },
    value(newValue) {
      this.internalValue = newValue; // Sincroniza si el valor de `value` cambia desde el padre
      this.$nextTick(() => this.adjustHeight());
    }
  },
  created() {
    // Inicializa la función debounce con un retraso de 300 ms
    this.debouncedEmit = this.debounce(value => {
      this.$emit('input', value); // Emite el evento `input` al padre para actualizar el valor
    }, 300);
  },
  mounted() {
    this.$nextTick(() => this.adjustHeight()); // Ajustar al cargar
  },
  methods: {
    // Función de debounce que retrasa la ejecución de `func` hasta después de `wait` ms
    debounce(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    adjustHeight() {
      if (this.$refs.textarea) {
        // console.log('log')
        const textarea = this.$refs.textarea;
        textarea.style.height = 'auto'; // Resetear altura
        textarea.style.height = `${textarea.scrollHeight}px`; // Ajustar altura
      }
    },
  }
    // computed: {
    //     internalModel: {
    //         get() {
    //             return this.model;
    //         },
    //         set(value) {
    //             this.$emit('update:model', value);
    //         }
    //     }
    // },
    // data() {
    //     return {
    //         localModel: this.model
    //     };
    // },
    // watch: {
    //     // model(newVal) {
    //     //     this.localModel = newVal;
    //     // },
    //     // localModel() {
    //     //     // this.updateModel()
    //     // }
    // },
    // methods: {
    //     // updateModel() {
    //     //     this.$emit('update:model', this.localModel);
    //     //     // this.$refs.myTextarea.focus();
    //     // }
    // }
}
</script>