<template>
    <div class="aspect-ratio-selector">
      <h3 style="color: #fff !important;">Aspect Ratio</h3>
      <div class="grid">
        <div
          v-for="ratio in filteredRatios"
          :key="ratio.value"
          :class="['ratio-box', { selected: selectedRatio === ratio.value }]"
          @click="selectRatio(ratio.value)"
        >
          <span>{{ ratio.label }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedRatio: "1:1",
        ratios: [
          { label: "1:1", value: "1:1" },
          { label: "3:2", value: "3:2" },
          { label: "4:3", value: "4:3" },
          { label: "3:4", value: "3:4" },
          { label: "16:9", value: "16:9" },
          { label: "9:16", value: "9:16" },
          
          
        //   { label: "4:5", value: "4:5" },
        //   { label: "5:4", value: "5:4" },
        //   { label: "5:12", value: "5:12" }, // Estos se excluirán
        //   { label: "12:5", value: "12:5" }  // Estos se excluirán
        ]
      };
    },
    computed: {
      filteredRatios() {
        return this.ratios.filter(ratio => !["5:12", "12:5"].includes(ratio.value));
      }
    },
    methods: {
      selectRatio(ratio) {
        this.selectedRatio = ratio;
        this.$emit("update-ratio", ratio); // Para comunicarlo al padre
      }
    }
  };
  </script>
  
  <style scoped>
  .aspect-ratio-selector {
    width: 250px;
    background: #111;
    padding: 15px;
    border-radius: 8px;
    color: white;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .ratio-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  .ratio-box.selected {
    background: white;
    color: black;
  }
  </style>
  