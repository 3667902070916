<template>
  <b-sidebar
    id="screenshot-take-screenshot"
    name="screenshot-take-screenshot"
    :visible="isTakeScreenshotSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="loadingForm"
    @change="(val) => $emit('update:isTakeScreenshotSidebarActive', val)"
  >
    <div class="sidebar-header d-flex justify-content-between content-sidebar-header px-2 pb-2">
        <header-sidebar :idsite-header.sync="idsite" />
        <span class="flex justify-end w-1/12">
          <feather-icon
            id="exitIcon"
            size="21"
            icon="XIcon"
            class="mt-2"
            :style="loadingForm ? 'cursor: no-drop;' : 'cursor: pointer;'"
            :disabled="true"
            @click="!loadingForm ? closeSidebar() : null"
          />
          <feather-icon
            id="backIcon"
            size="21"
            icon="ArrowLeftIcon"
            class=""
            :style="loadingForm ? 'cursor: no-drop;' : 'cursor: pointer;'"
            :disabled="true"
            @click="!loadingForm ? closeSidebar() : null"
          />
        </span>
    </div>
    <div class="image-in-sidebar pt-2">
        <b-img-lazy
          :src="imgScreenshot"
          height="104px"
          width="122px"
          style="box-shadow: none;"
          alt="Take-Scr"
        />

        <div class="m-auto p-2">
          <b-overlay
            id="overlay-background"
            :show="loadingForm"
            :variant="'transparent'"
            :opacity="0.85"
            :blur="'2px'"
            rounded="sm"
          >
            <validation-observer ref="rulesForm">
              <b-form>
                <b-form-group
                  label="Comment:"
                  class="mx-auto mt-1"
                  style="font-weight: 700; max-width: 420px !important"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Comment"
                    rules="required|max: 72"
                  >
                    <b-form-textarea
                      v-model="commentTakeScreenshot"
                      placeholder="Write comment"
                      rows="3"
                      style="max-width: 420px !important"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                
                <div class="mt-2">
                  <b-form-group
                      label="Select page:"
                      class="m-auto"
                      style="font-weight: 700; max-width: 420px !important"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Page"
                      rules="required"
                    >
                      <!-- <vue-autosuggest
                        v-model="pageTakeScreenshot"
                        :suggestions="suggestions"
                        :input-props="inputProps"
                        :section-configs="sectionConfigs"
                        :render-suggestion="renderSuggestion"
                        :limit="10"
                        @input="fetchResults"
                        class="select-pro-take-screenshot m-auto"
                        style="max-width: 320px !important"
                        :state="errors.length > 0 ? false:null"
                      /> -->
                      <b-form-radio-group
                        v-model="pageTakeScreenshot"
                        value-field="url"
                        text-field="title"
                        stacked
                        class="select-pro-take-screenshot m-auto"
                        style="max-width: 420px !important; font-weight: 400; max-height: calc(-605px + 100vh); overflow-y: auto; min-height: 100px;"
                        :state="errors.length > 0 ? false:null"
                      >
                        <b-form-radio v-for="(page, idpage) in proPages" :key="idpage" :value="page.url">
                          <p class="pb-0 mb-0" style="font-weight: 700; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 360px; display: block;">{{page.title}}</p>
                          <span style="color: #828282; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 360px; display: block;">{{getPathnamePage(page.url)}}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="w-100">
                  <b-button
                    variant="flat-primary"
                    class="mt-1"
                    :style="'background: #fff; border-radius: 12px; opacity: 1; text-align: center; letter-spacing: 0px; height: 44px; border: 0px; color: rgb(120, 56, 255); margin-left: 25px;'"
                    @click.prevent="openProPages"
                  >
                    Select more pages
                  </b-button>
                </div>
                <b-button
                  variant="primary"
                  class="d-block float-right"
                  :style="loadingForm ? 'cursor: no-drop; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px; border-radius: 22px; opacity: 1; text-align: center; letter-spacing: 0px; color: #F9F9F9; height: 44px; border: 0px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px; border-radius: 22px; opacity: 1; text-align: center; letter-spacing: 0px; color: #F9F9F9; height: 44px; border: 0px;'"
                  :disabled="loadingForm"
                  @click="validationForm"
                >
                  Take screenshot
                </b-button>
              </b-form>
            </validation-observer>
          </b-overlay>
        </div>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BImgLazy, BFormGroup, BFormTextarea, BButton, BForm, BFormRadioGroup, BFormRadio, BOverlay
} from 'bootstrap-vue'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import PreviewsTitle from './PreviewsTitle.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import screenshotsRepository from '../services'

export default {
  name: 'SidebarTakeScreenshot',
  components: {
    BSidebar,
    BImgLazy,
    BFormGroup,
    BFormTextarea,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BOverlay,
    VueAutosuggest,
    HeaderSidebar,
    PreviewsTitle,
    screenshotsRepository,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    proPages: {
      type: Array,
      required: true,
    },
    idsite: {
      type: Object,
      required: false,
    },
    isTakeScreenshotSidebarActive: {
        type: Boolean,
        required: true
    }
  },
  data() {
    return {
        max,
        required,
        datasuggest: [],
        suggestions: [],
        pageTakeScreenshot: null,
        commentTakeScreenshot: null,
        inputProps: {
            id: 'autosuggest__input',
            class: 'form-control',
            placeholder: "Select page",
        },
        selected: null,
        sectionConfigs: {
            developers: {
                limit: 6,
                label: 'Pro pages',
                onSelected: selected => {
                    this.pageTakeScreenshot = selected.item.title
                    this.selected = selected.item
                },
            },
            pages: {
                limit: 6,
                label: '',
                onSelected: () => {
                    this.openProPages()
                },
            },
        },
        limit: 10,
        internalIsTakeScreenshotSidebarActive: this.isTakeScreenshotSidebarActive,
        imgScreenshot: require('@/assets/images/illustration/screenshots.png'),
        loadingForm: false,
    }
  },
  watch: {
    isTakeScreenshotSidebarActive(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
    proPages(val) {
        // console.log(val)
    },
    pageTakeScreenshot(val) {
        if(!val) {
            this.selected = null
        }
    }
  },
  methods: {
    getPathnamePage(pageURL) {
      var urlConvert = new URL(pageURL)
      var urlConvertToPath = urlConvert.pathname === '/' ? 'Homepage' : urlConvert.pathname
      return urlConvertToPath
    },
    fetchResults(text) {
    //   const { query } = this
      if (text) {
        this.suggestions = []
      }
    //   console.log(text)

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.proPages.filter(item => item.title.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
    //   const filteredPages = this.proPages.filter(item => item.title.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredPages = [{title: 'Add more pages'}]
      filteredDevelopers.length && this.suggestions.push({ name: 'developers', data: filteredDevelopers })
      filteredPages.length && this.suggestions.push({ name: 'pages', data: filteredPages })
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'developers') {
        const dev = suggestion.item
        return (
          <div class="d-flex align-items-center">
            <div class="detail">
              <small class="text-muted">{dev.title}</small>
            </div>
          </div>
        )
      }
      if (suggestion.name === 'pages') {
        const page = suggestion.item
        return (
          <div class="d-flex align-items-center">
            <button style="background: #CA34FF !important; color: #fff; border: 0px; padding: 5px 50px; padding-top: 5px; padding-right: 50px; padding-bottom: 5px; padding-left: 50px; border-radius: 10px;">{page.title}</button>
          </div>
        )
      }
      return false
    },
    handleSidebarVisibilityChange(val) {
      this.internalIsTakeScreenshotSidebarActive = val
      this.$emit('update:isTakeScreenshotSidebarActive', val)
      this.loadingForm = false
    },
    closeSidebar() {
      this.handleSidebarVisibilityChange(false)
    },
    openProPages() {
        this.loadingForm = true
        this.pageTakeScreenshot = null
        // console.log('open pro')
        this.closeSidebar()
        localStorage.setItem('viewTSPro', 'true')
        setTimeout(() => {
            this.loadingForm = false
        }, 1500)
    },
    async takeScreenshot() {
        // console.log(this.pageTakeScreenshot)
        if(!this.pageTakeScreenshot) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: `Select page first`,
                    icon: 'BellIcon',
                    variant: 'warning',
                },
            })

            setTimeout(() => {
                this.loadingForm = false
            }, 1500)
        } else {
            // var objTakeScreen = {
            //   website: this.pageTakeScreenshot,
            //   comment: this.commentTakeScreenshot,
            //   userId: 'vendor4@shops.gt'
            // }

            try {
                const response = await screenshotsRepository.actions.takeScreenshotNow(this.pageTakeScreenshot,this.commentTakeScreenshot)
                if(response) {
                  localStorage.setItem('changeScreen', 'true')
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: `Success taking screenshot`,
                          icon: 'BellIcon',
                          variant: 'success',
                      },
                  })
                  this.selected = null
                  this.pageTakeScreenshot = null
                  this.commentTakeScreenshot = null
                  this.closeSidebar()
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Error taking screenshot`,
                            icon: 'BellIcon',
                            variant: 'danger',
                        },
                    })
                }

                setTimeout(() => {
                    this.loadingForm = false
                }, 1500)
            } catch(e) {
                console.error('Error take screenshot sidebar: ', e)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error taking screenshot`,
                        icon: 'BellIcon',
                        variant: 'danger',
                    },
                })
                setTimeout(() => {
                    this.loadingForm = false
                }, 1500)
            }
        }
    },
    validationForm() {
      // console.log(this.siteProject)
      this.loadingForm = true
      this.$refs.rulesForm.validate().then(success => {
        if (success) {
            this.takeScreenshot()
            // console.log('success form')
        } else {
          this.loadingForm = false
        }
      })
    },
  }
}
</script>

<style>
#screenshot-take-screenshot {
  width: 90%;
  max-width: 600px !important;
  background: white;
}
#screenshot-take-screenshot .image-in-sidebar img{
  margin:auto;

  box-shadow:
  0px 0px 2.2px rgba(0, 0, 0, 0.036),
  0px 0px 5.3px rgba(0, 0, 0, 0.054),
  0px 0px 10px rgba(0, 0, 0, 0.069),
  0px 0px 17.9px rgba(0, 0, 0, 0.085),
  0px 0px 33.4px rgba(0, 0, 0, 0.106),
  0px 0px 80px rgba(0, 0, 0, 0.15)
;
}
.image-in-sidebar{
  padding: 0 25px;
  margin-bottom: 25px;
}
.image-in-sidebar img {
  margin: auto;
  max-width: 100% !important;
  height: auto !important;
  display: block !important;
}
#screenshot-take-screenshot h3{
  text-transform: capitalize;
  color: #5e5873;
  font: normal normal bold 23px/30px "Hurme Geometric Sans 3" !important;
}
#screenshot-take-screenshot .sidebar-header{
  background: rgb(240, 241, 247);
  margin-bottom: 20px;
  padding: 0 25px;
}
#screenshot-take-screenshot .sidebar-header p{
  margin-bottom: 0;
}
#screenshot-take-screenshot .sidebar-header p:last-child{
  margin-bottom: 0.5rem;
}
#backIcon{
  display: none;
}
.select-pro-take-screenshot ul {
    display: block !important;
    flex-wrap: inherit !important;
}
.select-pro-take-screenshot .custom-radio {
  padding-bottom: 10px;
}
@media (max-width:840px){
  #screenshot-take-screenshot{
    width: 100%;
  }
  #exitIcon{
    display: none;
  }
  #backIcon{
    display: block;
  }
}
</style>