<template>
<div>
    <img
        style="width: auto; height: auto; max-height: 250px; max-width: 100%; object-fit: cover;"
        :src="imageSrc"
        alt="Missing img"
    />
</div>
</template>

<script>
import { BImgLazy, } from 'bootstrap-vue'

export default {
    components: {
        BImgLazy
    },
    props: {
        status: {
            type: Number,
            default: 0
        },
        img: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            imageSrc: this.getImageSrc()
        };
    },
    watch: {
        status: 'updateImageSrc',
        img: 'updateImageSrc'
    },
    methods: {
        replaceWithPlaceholder(event) {
            event.target.src = "https://src.seocloud.ai/siteImg/1739986479791siteImg";
        },
        updateImageSrc() {
            this.imageSrc = this.getImageSrc();
        },
        getImageSrc() {
            // : this.img; : require('@/assets/images/pages/auth-v1-top-bg.png')
            return this.status === 0 
                ? require('@/assets/images/pages/auth-v1-top-bg.png')
                : this.img;
        }
    }
}
</script>