<template>
    <div>
        <b-sidebar
            id="sidebar-right-edit-image"
            ref="sidebar_edit_image"
            :visible="showOpenEditImage"
            bg-variant="white"
            right
            backdrop
            shadow
            no-header
            @change="(val) => $emit('update:show-open-edit-image', val)"
        >
            <template #default="{  }">
                <div class="pl-0 px-2 pt-75 pb-50 header-copilot d-flex" style="background: #fff; border: none; position: initial !important;">
                    <div class="" style="width: 4%;">
                        <b-button
                            variant="flat-light"
                            style="color: #c1c1c1; display: block; text-align: left; width: 40px;"
                            class="btn-icon pl-0 mb-0 pb-25 px-1 mt-1"
                            @click="confirmationCloseSidebar()"
                        >
                            <feather-icon
                                icon="ArrowLeftIcon"
                                size="25"
                            />
                        </b-button>
                    </div>

                    <div class="w-100">
                        <div class="">
                            <b-button
                                variant="flat-light"
                                style="color: #c1c1c1; display: block; text-align: left; width: 40px; float: right; position: relative; top: -4px;"
                                class="btn-icon pl-0 mb-0 pb-25 px-1 mt-1"
                                @click="confirmationCloseSidebar()"
                            >
                                <feather-icon
                                    icon="XIcon"
                                    size="25"
                                />
                            </b-button>
                            <b-button
                                v-clipboard:copy="`${actualVisitURL}/share?site=${siteObj && siteObj.length ? siteObj[0].domain : siteHomepage}&imgid=${idErrorSelect}`"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                :style="shareCopiedUrl ? 'background: #28c76f !important; width: 96px; height: 26px; margin-right: 5px; display: flex; color: #fff !important; font-size: 15px; padding: 5px 15px; border: 1px solid #28c76f !important; border-radius: 10px !important; float: right; cursor: default;' : 'background: transparent !important; width: 96px; height: 26px; margin-right: 5px; display: flex; color: rgb(167, 170, 185) !important; font-size: 15px; padding: 5px 15px; border: 1px solid #ddd !important; border-radius: 10px !important; float: right;'"
                                class="eyeBtns linkURLImg rounded-circle mt-2"
                            >
                                <feather-icon :icon="shareCopiedUrl ? 'CheckIcon' : 'CopyIcon'" size="16" :style="shareCopiedUrl ? 'color: #fff !important;' : 'color: rgb(176, 176, 176) !important;'" /><span class="ml-25">{{shareCopiedUrl ? 'Copied' : 'Share'}}</span>
                            </b-button>
                            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
                            Page:
                            </p>
                            <p class="card-text mb-0 pt-50 name-site-header-sidebar-copilot" style="font-weight: 400; color: #181E38; font-size: 15px;">
                            {{ IASiteSelected && IASiteSelected.length ? IASiteSelected[0].title : '' }}
                            </p>
                            <a :href="IASiteSelected && IASiteSelected.length ? IASiteSelected[0].cUrl : ''" target="_blank" style="display: block; max-width: fit-content;">
                                <span
                                    class="url-header-sidebar-copilot"
                                    style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
                                >
                                    {{ IASiteSelected && IASiteSelected.length ? IASiteSelected[0].cUrl : '' }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div style="background: #fff; padding-bottom: 10px !important;">
                    <div class="text-right" style="margin-top: 0px;">
                        <div style="width: 250px !important; text-align: left; margin-left: 25px;" v-if="objErrImg.fixedImg && objErrImg.initImg">
                            <label for="textarea-default" style="font-size: 1rem; margin-bottom: 0px;">Source:</label>
                            <b-form-select
                                v-model="imgValueTrack"
                                :options="optionsTrackImgs"
                                size="sm"
                                class="mt-25"
                            />
                        </div>
                        <div style="width: 250px !important; text-align: left; margin-left: 25px;" v-else>
                            <label for="textarea-default" style="font-size: 1rem; margin-bottom: 0px;">Source:</label>
                            <b-form-select
                                v-model="imgValueTrackOriginal"
                                :options="optionsTrackImgsOriginal"
                                size="sm"
                                class="mt-25"
                            />
                        </div>

                        <div class="header-img-ia">
                            <!-- info img location -->
                            <div class="left-header-img-ia">
                                <!-- <span style="display: inline-flex; word-break: break-word;justify-content: initial; display: flex; padding-top: 15px; padding-left: 30px; text-align: left;">
                                    Image location:
                                </span> -->
                                <span style="display: inline-flex; word-break: break-word;justify-content: initial; display: flex; padding-top: 0px; padding-left: 30px; text-align: left; margin-top: 5px;">
                                    <b-button
                                        :style="'background: transparent !important; border: 0px; margin-top: 0px; display: inline !important; margin-right: 5px;'"
                                        target="_blank"
                                        :href="objErrImg.fixedImg && objErrImg.initImg ? urlImgToModified : objErrImg.img"
                                        class="p-0 eyeBtns linkURL"
                                        v-if="objErrImg.img"
                                    >
                                        <feather-icon
                                            icon="ExternalLinkIcon"
                                            class="cursor-pointer eyeBtns"
                                            style="color: rgb(176, 176, 176) !important; margin-top: 2px;"
                                            size="16"
                                        />
                                    </b-button>
                                    {{getPathURLImg(objErrImg.fixedImg && objErrImg.initImg ? urlImgToModified : objErrImg.img)}}
                                </span>
                            </div>
                            <!-- btns tabs -->
                            <div class="right-header-img-ia">
                                <div class="d-flex justify-content-end" style="margin-right: 15px; margin-top: 0px !important;">
                                    <div class="btn-group p-0 rounded-pill" style="margin-top: 0px !important;">
                                        <!-- <button
                                            @click="(showBoxTab = (activeTab !== 'prompt' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('prompt', false) : activeTab = 'prompt')"
                                            :class="activeTab === 'prompt' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0"
                                            :style="activeTab === 'prompt' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 85px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 85px !important;'"
                                            v-if="suggestIaForm && userEmail === 'vendor4@shops.gt'"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'prompt' ? logoIconAIDark : logoIconAIDark"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Prompt</span>
                                        </button> -->
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'generate' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('generate', false) : activeTab = 'generate')"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'generate' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 142px !important;' : activeTab === 'generate' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 142px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 142px !important;'"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'generate' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Image generator</span>
                                        </button>
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'reimagine' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('reimagine', false) : activeTab = 'reimagine')"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'reimagine' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 100px !important;' : activeTab === 'reimagine' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 100px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 100px !important;'"
                                            :disabled="isErrorImg ? true : false"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'reimagine' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Reimagine</span>
                                        </button>
                                        <!-- <button
                                            @click="activeTab = 'png'"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'png' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? : activeTab === 'png' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px;'"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'png' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">PNG generator</span>
                                        </button> -->
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'upscale' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('upscale', false) : upscaleImage())"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'upscale' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 85px !important;' : activeTab === 'upscale' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 85px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 85px !important;'"
                                            :disabled="isErrorImg ? true : false"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'upscale' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Upscale</span>
                                        </button>
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'remover' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('remover', false) : activeTab = 'remover')"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'remover' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 141px !important;' : activeTab === 'remover' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 141px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 141px !important;'"
                                            :disabled="isErrorImg ? true : false"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'remover' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Section Remover</span>
                                        </button>
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'resize' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('resize', false) : activeTab = 'resize')"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'resize' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px;  width: 128px !important;' : activeTab === 'resize' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px;  width: 128px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px;  width: 128px !important;'"
                                            :disabled="isErrorImg ? true : false"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'resize' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Image expand</span>
                                        </button>
                                        <button
                                            @click="(showBoxTab = (activeTab !== 'background' ? true : showBoxTab ? false : true)), (getStatusTab() ? confirmationChangeTab('background', false) : activeTab = 'background')"
                                            :class="!initOpenSidebar ? 'text-dark btn-tab-images-ia' : activeTab === 'background' ? 'text-dark btn-tab-images-ia-active' : 'text-dark btn-tab-images-ia'"
                                            class="btn rounded-pill px-0 pt-0"
                                            :style="initOpenSidebar ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 167px !important;' : activeTab === 'background' ? 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 167px !important;' : 'border: 0px; border-radius: 0px !important; padding-bottom: 5px; width: 167px !important;'"
                                            :disabled="isErrorImg ? true : false"
                                        >
                                            <b-img-lazy
                                                fluid
                                                :src="activeTab === 'background' ? logoIconAIColor : logoIconAIColor"
                                                alt="ai img"
                                                :width="'22'"
                                                :height="'24'"
                                                :style="''"
                                                :class="''"
                                                loading="lazy"
                                            />
                                            <span style="position: relative; top: 4px; margin-left: 6px;">Background remover</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- prompt -->
                <div style="position: relative; width: 100%;" v-if="promptSendApi && userEmail === 'vendor4@shops.gt' && (activeTab === 'generate' || activeTab === 'resize')">
                    <button
                        @click="showHidePrompt()"
                        :class="'btn-primary text-white btn-mod-img-generated'"
                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                        style="background: #fff !important; color: #7838ff !important; position: absolute; left: 10px; top: 15px;"
                    >
                        {{showPrompt ? 'Hide Prompt' : 'Show Prompt'}}
                    </button>
                    <div class='box-image-prompt' v-if="showPrompt">
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate">
                            <label for="textarea-default">Prompt:</label>
                            <b-form-textarea
                                id="textarea-default"
                                placeholder="Prompt"
                                v-model="promptSendApi"
                                plaintext
                                rows="4"
                                max-rows="15"
                                style="overflow-y: hidden;"
                            />
                        </div>
                    </div>
                </div>
                
                <!-- boxes tabs -->
                <div style="position: relative; width: 100%;">
                    <div v-if="showBoxTab" :class="activeTab === 'generate' ? 'box-image-generate' : activeTab === 'reimagine' ? 'box-image-reimagine' : activeTab === 'upscale' ? 'box-image-upscale' : activeTab === 'remover' ? 'box-image-remover' : activeTab === 'resize' ? 'box-image-resize' : activeTab === 'background' ? 'box-image-background' : activeTab === 'prompt' ? '' : ''">
                        <!-- <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-if="activeTab === 'prompt'">
                            <div class="px-4">
                                <label for="textarea-default">Prompt:</label>
                                <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Prompt"
                                    v-model="suggestIaForm"
                                    plaintext
                                    rows="4"
                                    max-rows="15"
                                    style="overflow-y: hidden;"
                                />
                            </div>
                        </div> -->
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-if="activeTab === 'generate'">
                            <div class="w-100 mx-0 px-2" style="margin-bottom: 15px;">
                                <label for="textarea-default">Prompt:</label>
                                <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Write a prompt to generate"
                                    rows="3"
                                    v-model="promptGenerate"
                                />
                            </div>
                            <b-row style="margin-bottom: 15px;">
                                <b-col md="6" style="padding-left: 30px;">
                                    <span style="text-align: left !important; width: 100% !important; display: block;">Model: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                    <b-form-select
                                        v-model="modelValueGenerate"
                                        :options="optionsModels"
                                        size="lg"
                                        class="mt-25"
                                    />
                                </b-col>
                                <!-- <b-col md="6" style="justify-content: center; display: flex;"> -->
                                <b-col md="6" style="padding-left: 30px;">
                                    <!-- <AspectRatioSelector @update-ratio="handleRatioChange" /> -->
                                    <span style="text-align: left !important; width: 100% !important; display: block;">Aspect ratio: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                    <b-form-select
                                        v-model="aspectRatioVlue"
                                        :options="optionsAspectRatio"
                                        size="lg"
                                        class="mt-25"
                                    />
                                </b-col>
                            </b-row>
                            <button
                                @click="generateImage()"
                                :class="'btn-primary text-white'"
                                class="btn rounded-pill px-4 float-right mr-1 mt-75"
                                :disabled="processingData ? true : promptGenerate ? false : true"
                            >
                            Generate 
                            <b-spinner
                                small
                                class="ml-25"
                                variant="light"
                                style="position: relative; top: -1px;"
                                v-if="processingData"
                            />
                            </button>
                        </div>
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-else-if="activeTab === 'reimagine'">
                            <div class="px-50" v-if="objErrImg.exist && !editIAImg">
                                <!-- <label for="file-upload1" class="custom-file-upload">
                                    <span>Click to upload</span>
                                </label>
                                <input type="file" id="file-upload1" @change="handleFileUpload" accept="image/*" />

                                <div v-if="imagePreview" class="preview-container">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <button
                                    @click="logicReimagine()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : false"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                            <div class="px-50" v-else>
                                <!-- <div v-if="imagePreview" class="preview-container">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <button
                                    @click="logicReimagine()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : false"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-else-if="activeTab === 'upscale'">
                            <div class="px-50" v-if="objErrImg.exist && !editIAImg">
                                <label for="file-upload1" class="custom-file-upload">
                                    <span>Click to upload</span>
                                </label>
                                <input type="file" id="file-upload1" @change="handleFileUpload" accept="image/*" />

                                <div v-if="imagePreview" class="preview-container">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageUpscale()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFile ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                            <div class="px-50" v-else>
                                <!-- <div v-if="imagePreview" class="preview-container">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <button
                                    @click="uploadImageUpscale()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFile ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-else-if="activeTab === 'remover'">
                            <div class="px-50" v-if="objErrImg.exist && !editIAImg">
                                <label for="file-upload2" class="custom-file-upload">
                                    <span>Click to upload (original image)</span>
                                </label>
                                <input type="file" id="file-upload2" @change="handleFileUploadOriginalRemover" accept="image/*" />

                                <div v-if="imagePreviewOriginalRemover" class="preview-container">
                                    <img :src="imagePreviewOriginalRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <label for="file-upload3" class="custom-file-upload-mask">
                                    <span>Click to upload (original image indicating the areas to be removed)</span>
                                </label>
                                <input type="file" id="file-upload3" @change="handleFileUploadMaskRemover" accept="image/*" />

                                <div v-if="imagePreviewMaskRemover" class="preview-container">
                                    <img :src="imagePreviewMaskRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageRemover()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileOriginalRemover && selectedFileMaskRemover ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                            <div class="px-50" v-else>
                                <!-- <div v-if="imagePreviewOriginalRemover" class="preview-container">
                                    <img :src="imagePreviewOriginalRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <label for="file-upload3" class="custom-file-upload-mask">
                                    <span>Click to upload (original image indicating the areas to be removed)</span>
                                </label>
                                <input type="file" id="file-upload3" @change="handleFileUploadMaskRemover" accept="image/*" />

                                <div v-if="imagePreviewMaskRemover" class="preview-container">
                                    <img :src="imagePreviewMaskRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageRemover()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileOriginalRemover && selectedFileMaskRemover ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-else-if="activeTab === 'resize'">
                            <div class="px-50" v-if="objErrImg.exist && !editIAImg">
                                <div class="w-100 mx-0 px-2 mb-75">
                                    <label for="textarea-default">Prompt:</label>
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="A text description that guides the out-painting process. The image will be modified to align with this prompt in the masked areas."
                                        rows="3"
                                        v-model="promptResize"
                                    />
                                </div>

                                <label for="file-upload4" class="custom-file-upload">
                                    <span>Click to upload (original image)</span>
                                </label>
                                <input type="file" id="file-upload4" @change="handleFileUploadOriginalResize" accept="image/*" />

                                <div v-if="imagePreviewOriginalResize" class="preview-container">
                                    <img :src="imagePreviewOriginalResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <label for="file-upload5" class="custom-file-upload-mask">
                                    <span>Click to upload (original image indicating the areas to be out-painted)</span>
                                </label>
                                <input type="file" id="file-upload5" @change="handleFileUploadMaskResize" accept="image/*" />

                                <div v-if="imagePreviewMaskResize" class="preview-container">
                                    <img :src="imagePreviewMaskResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageResize()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileOriginalResize && selectedFileMaskResize && promptResize ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                            <div class="px-50" v-else>
                                <div class="w-100 mx-0 px-2 mb-75">
                                    <label for="textarea-default">Prompt:</label>
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="A text description that guides the out-painting process. The image will be modified to align with this prompt in the masked areas."
                                        rows="3"
                                        v-model="promptResize"
                                    />
                                </div>

                                <!-- <div v-if="imagePreviewOriginalResize" class="preview-container">
                                    <img :src="imagePreviewOriginalResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <label for="file-upload5" class="custom-file-upload-mask">
                                    <span>Click to upload (original image indicating the areas to be out-painted)</span>
                                </label>
                                <input type="file" id="file-upload5" @change="handleFileUploadMaskResize" accept="image/*" />

                                <div v-if="imagePreviewMaskResize" class="preview-container">
                                    <img :src="imagePreviewMaskResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageResize()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileOriginalResize && selectedFileMaskResize && promptResize ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="mt-75 d-block justify-content-center container-dropdown-generate" v-else-if="activeTab === 'background'">
                            <div class="px-50" v-if="objErrImg.exist && !editIAImg">
                                <label for="file-upload1" class="custom-file-upload">
                                    <span>Click to upload</span>
                                </label>
                                <input type="file" id="file-upload1" @change="handleFileUploadBack" accept="image/*" />

                                <div v-if="imagePreviewBack" class="preview-container">
                                    <img :src="imagePreviewBack" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>

                                <button
                                    @click="uploadImageBackground()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileBack ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                            <div class="px-50" v-else>
                                <!-- <div v-if="imagePreviewBack" class="preview-container">
                                    <img :src="imagePreviewBack" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div> -->

                                <button
                                    @click="uploadImageBackground()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1"
                                    :disabled="processingData ? true : selectedFileBack ? false : true"
                                >
                                Generate
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="objErrImg.fixedImg && objErrImg.initImg ? 'imgs-ia-container-fixedURL' : 'imgs-ia-container'" @click.prevent="showBoxTab = false" v-if="!errorChargingImg && objErrImg.exist && !editIAImg">
                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'generate'">
                        <div style="position: relative; width: 100%;" v-if="editIAImg || generatedImage">
                            <div class="mt-25 d-block justify-content-center" style="position: absolute; right: 4px;">
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'generate')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : generatedImage ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : generatedImage ? false : true"
                                    v-if="generatedImage"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>

                        <div class="text-center imgs-ia-container-div" v-if="!generatedImage">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="sourceImage" fluid :alt="errorChargingImg ? 'Image not available for display' : ''" v-if="!objErrImg.exist" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <!-- <div v-else>
                                    <div class="w-100 mx-0 px-2">
                                        <b-row style="margin-bottom: 15px;">
                                            <b-col md="6" style="padding-left: 30px;">
                                                <span style="text-align: left !important; width: 100% !important; display: block;">Model: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                                <b-form-select
                                                    v-model="modelValueGenerate"
                                                    :options="optionsModels"
                                                    size="lg"
                                                    class="mt-1"
                                                />
                                            </b-col>
                                            <b-col md="6" style="padding-left: 30px;">
                                                <span style="text-align: left !important; width: 100% !important; display: block;">Aspect ratio: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                                <b-form-select
                                                    v-model="aspectRatioVlue"
                                                    :options="optionsAspectRatio"
                                                    size="lg"
                                                    class="mt-1"
                                                />
                                            </b-col>
                                        </b-row>
                                        <label for="textarea-default">Prompt:</label>
                                        <b-form-textarea
                                            id="textarea-default"
                                            placeholder="Write a prompt to generate"
                                            rows="3"
                                            v-model="promptGenerate"
                                        />
                                    </div>
                                    <button
                                        @click="generateImage()"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-1 mt-75"
                                        :disabled="processingData ? true : promptGenerate ? false : true"
                                    >
                                    Generate 
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img px-50">
                                <b-img :src="generatedImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'reimagine'">
                        <div style="position: relative; width: 100%;" v-if="editIAImg || reimaginateImage">
                            <div class="mt-25 d-block justify-content-center" style="position: absolute; right: 4px;">
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'reimagine')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : reimaginateImage ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : reimaginateImage ? false : true"
                                    v-if="reimaginateImage"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>

                        <div class="text-center imgs-ia-container-div" v-if="!reimaginateImage">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="sourceImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <!-- v-if="editIAImg" -->
                                <!-- <b-overlay
                                    v-else
                                    id="overlay-background"
                                    :show="processingData ? true : false"
                                    :variant="variant"
                                    :opacity="opacity"
                                    :blur="blur"
                                    rounded="sm"
                                >
                                    <div style="width: 100%; height: 450px;"></div>
                                </b-overlay> -->
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="reimaginateImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'png'">
                        <div class="text-center imgs-ia-container-div" v-if="!generatedImagePNG">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <div class="w-100 mx-0 px-2">
                                    <label for="textarea-default">Prompt:</label>
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="Write a prompt to generate png"
                                        rows="3"
                                        v-model="promptGeneratePNG"
                                    />
                                </div>
                                <button
                                    @click="generateImagePNG()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1 mt-75"
                                    :disabled="processingData ? true : promptGeneratePNG ? false : true"
                                >
                                Generate PNG
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="generatedImagePNG" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'generatePNG')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : generatedImagePNG ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : generatedImagePNG ? false : true"
                                    v-if="generatedImagePNG"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'upscale'">
                        <div class="imgs-ia-container-div" v-if="uploadUpscaleTab">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || enhancedUploadImage">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'upscale')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : enhancedUploadImage ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : enhancedUploadImage ? false : true"
                                        v-if="enhancedUploadImage"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="enhancedUploadImage"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="originalUploadImage && enhancedUploadImage">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <!-- eslint-disable -->
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="originalUploadImage">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="enhancedUploadImage" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                    <!-- eslint-enable -->
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreview" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                        <div class="imgs-ia-container-img" v-else>
                            <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}" v-if="originalImage && enhancedImage">
                                <!-- eslint-disable -->
                                <figure slot="first" class="before">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/before.webp">
                                    <figcaption>Before</figcaption>
                                </figure>
                                <figure slot="second" class="after">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/after.webp" >
                                    <figcaption>After</figcaption>
                                </figure>
                                <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                    <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                </svg>
                                <!-- eslint-enable -->
                            </img-comparison-slider>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'remover'">
                        <div class="imgs-ia-container-div">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || imgEnhancedRemover">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'remover')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : imgEnhancedRemover ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : imgEnhancedRemover ? false : true"
                                        v-if="imgEnhancedRemover"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="imgOriginalRemover && imgEnhancedRemover">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgOriginalRemover">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgEnhancedRemover" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewOriginalRemover" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewOriginalRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'resize'">
                        <div class="imgs-ia-container-div">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || imgEnhancedResize">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'resize')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : imgEnhancedResize ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : imgEnhancedResize ? false : true"
                                        v-if="imgEnhancedResize"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="imgOriginalResize && imgEnhancedResize">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgOriginalResize">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgEnhancedResize" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewOriginalResize" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewOriginalResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'background'">
                        <div class="imgs-ia-container-div" v-if="uploadUpscaleTabBack">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || enhancedUploadImageBack">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'background')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : enhancedUploadImageBack ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : enhancedUploadImageBack ? false : true"
                                        v-if="enhancedUploadImageBack"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="originalUploadImageBack && enhancedUploadImageBack">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <!-- eslint-disable -->
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="originalUploadImageBack">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="enhancedUploadImageBack" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                    <!-- eslint-enable -->
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewBack" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewBack" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                        <div class="imgs-ia-container-img" v-else>
                            <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}" v-if="originalImageBack && enhancedImageBack">
                                <!-- eslint-disable -->
                                <figure slot="first" class="before">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/before.webp">
                                    <figcaption>Before</figcaption>
                                </figure>
                                <figure slot="second" class="after">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/after.webp" >
                                    <figcaption>After</figcaption>
                                </figure>
                                <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                    <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                </svg>
                                <!-- eslint-enable -->
                            </img-comparison-slider>
                        </div>
                    </div>
                </div>
                <!-- <div v-else-if="editIAImg"> -->
                <div :class="objErrImg.fixedImg && objErrImg.initImg ? 'imgs-ia-container-fixedURL' : 'imgs-ia-container'" @click.prevent="showBoxTab = false" v-else>
                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'generate'">
                        <div style="position: relative; width: 100%;" v-if="editIAImg || generatedImage">
                            <div class="mt-25 d-block justify-content-center" style="position: absolute; right: 4px;">
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'generate')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : generatedImage ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : generatedImage ? false : true"
                                    v-if="generatedImage"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>

                        <div class="text-center imgs-ia-container-div" v-if="!generatedImage">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img px-50">
                                <b-img :src="sourceImage" fluid :alt="errorChargingImg ? 'Image not available for display' : ''" v-if="!objErrImg.exist" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <!-- <div v-else>
                                        <div class="w-100 mx-0 px-2">
                                            <b-row style="margin-bottom: 15px;">
                                                <b-col md="6" style="padding-left: 30px;">
                                                    <span style="text-align: left !important; width: 100% !important; display: block;">Model: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                                    <b-form-select
                                                        v-model="modelValueGenerate"
                                                        :options="optionsModels"
                                                        size="lg"
                                                        class="mt-1"
                                                    />
                                                </b-col>
                                                <b-col md="6" style="padding-left: 30px;">
                                                    <span style="text-align: left !important; width: 100% !important; display: block;">Aspect ratio: <span style="font-size: 12px; color: rgb(167, 170, 185)">(optional)</span></span>
                                                    <b-form-select
                                                        v-model="aspectRatioVlue"
                                                        :options="optionsAspectRatio"
                                                        size="lg"
                                                        class="mt-1"
                                                    />
                                                </b-col>
                                            </b-row>
                                            <label for="textarea-default">Prompt:</label>
                                            <b-form-textarea
                                                id="textarea-default"
                                                placeholder="Write a prompt to generate"
                                                rows="3"
                                                v-model="promptGenerate"
                                            />
                                        </div>
                                        <button
                                            @click="generateImage()"
                                            :class="'btn-primary text-white'"
                                            class="btn rounded-pill px-4 f
                                        </div>loat-right mr-1 mt-75"
                                        :disabled="processingData ? true : promptGenerate ? false : true"
                                    >
                                    Generate 
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="generatedImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <!-- <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'generate')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : generatedImage ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : generatedImage ? false : true"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button> -->
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'reimagine'">
                        <div style="position: relative; width: 100%;" v-if="editIAImg || reimaginateImage">
                            <div class="mt-25 d-block justify-content-center" style="position: absolute; right: 4px;">
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'reimagine')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : reimaginateImage ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : reimaginateImage ? false : true"
                                    v-if="reimaginateImage"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>

                        <div class="text-center imgs-ia-container-div" v-if="!reimaginateImage">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="sourceImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <!-- v-if="editIAImg" -->
                                <!-- <b-overlay
                                    v-else
                                    id="overlay-background"
                                    :show="processingData ? true : false"
                                    :variant="variant"
                                    :opacity="opacity"
                                    :blur="blur"
                                    rounded="sm"
                                >
                                    <div style="width: 100%; height: 450px;"></div>
                                </b-overlay> -->
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="reimaginateImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'png'">
                        <div class="text-center imgs-ia-container-div" v-if="!generatedImagePNG">
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="sourceImage" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <div class="w-100 mx-0 px-2">
                                    <label for="textarea-default">Prompt:</label>
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="Write a prompt to generate png"
                                        rows="3"
                                        v-model="promptGeneratePNG"
                                    />
                                </div>
                                <button
                                    @click="generateImagePNG()"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-1 mt-75"
                                    :disabled="processingData ? true : promptGeneratePNG ? false : true"
                                >
                                Generate PNG
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                        <div class="text-center imgs-ia-container-div" v-else>
                            <div class="mt-75 d-block justify-content-center imgs-ia-container-img">
                                <b-img :src="generatedImagePNG" fluid alt="Generated image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                <button
                                    @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'generatePNG')"
                                    :class="'btn-primary text-white'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    :disabled="processingSaveData ? true : editIAImg ? false : generatedImagePNG ? false : true"
                                >
                                Save
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingSaveData"
                                />
                                </button>
                                <button
                                    @click="loadImgIAGenerated()"
                                    :class="'btn-primary text-white btn-mod-img-generated'"
                                    class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                    style="background: #fff !important; color: #7838ff !important;"
                                    :disabled="processingData ? true : generatedImagePNG ? false : true"
                                    v-if="generatedImagePNG"
                                >
                                Apply
                                <b-spinner
                                    small
                                    class="ml-25"
                                    variant="light"
                                    style="position: relative; top: -1px;"
                                    v-if="processingData"
                                />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'upscale'">
                        <div class="imgs-ia-container-div" v-if="uploadUpscaleTab">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || enhancedUploadImage">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'upscale')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : enhancedUploadImage ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : enhancedUploadImage ? false : true"
                                        v-if="enhancedUploadImage"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="originalUploadImage && enhancedUploadImage">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <!-- eslint-disable -->
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="originalUploadImage">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="enhancedUploadImage" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                    <!-- eslint-enable -->
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreview" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreview" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                        <div class="imgs-ia-container-img" v-else>
                            <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}" v-if="originalImage && enhancedImage">
                                <!-- eslint-disable -->
                                <figure slot="first" class="before">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/before.webp">
                                    <figcaption>Before</figcaption>
                                </figure>
                                <figure slot="second" class="after">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/after.webp" >
                                    <figcaption>After</figcaption>
                                </figure>
                                <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                    <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                </svg>
                                <!-- eslint-enable -->
                            </img-comparison-slider>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'remover'">
                        <div class="imgs-ia-container-div">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || imgEnhancedRemover">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'remover')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : imgEnhancedRemover ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : imgEnhancedRemover ? false : true"
                                        v-if="imgEnhancedRemover"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="imgOriginalRemover && imgEnhancedRemover">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgOriginalRemover">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgEnhancedRemover" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewOriginalRemover" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewOriginalRemover" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'resize'">
                        <div class="imgs-ia-container-div">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || imgEnhancedResize">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'resize')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : imgEnhancedResize ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : imgEnhancedResize ? false : true"
                                        v-if="imgEnhancedResize"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="imgOriginalResize && imgEnhancedResize">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgOriginalResize">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="imgEnhancedResize" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewOriginalResize" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewOriginalResize" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="background: #f0f0f0 !important;" class="mt-75 mx-auto imgs-ia-container-tab" v-if="activeTab === 'background'">
                        <div class="imgs-ia-container-div" v-if="uploadUpscaleTabBack">
                            <div style="position: relative; width: 100%;" v-if="editIAImg || enhancedUploadImageBack">
                                <div style="position: absolute; right: 4px; z-index: 9;">
                                    <button
                                        @click="saveImg({fixText: '', idc: 8, oldText: objErrImg.content ? objErrImg.content : '', idError: idErrorSelect, fixTextAlt: '', img: objErrImg.content ? objErrImg.content : '', idrequest: ''}, 'background')"
                                        :class="'btn-primary text-white'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        :disabled="processingSaveData ? true : editIAImg ? false : enhancedUploadImageBack ? false : true"
                                    >
                                    Save
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingSaveData"
                                    />
                                    </button>
                                    <button
                                        @click="loadImgIAGenerated()"
                                        :class="'btn-primary text-white btn-mod-img-generated'"
                                        class="btn rounded-pill px-4 float-right mr-2 mt-1"
                                        style="background: #fff !important; color: #7838ff !important;"
                                        :disabled="processingData ? true : enhancedUploadImageBack ? false : true"
                                        v-if="enhancedUploadImageBack"
                                    >
                                    Apply
                                    <b-spinner
                                        small
                                        class="ml-25"
                                        variant="light"
                                        style="position: relative; top: -1px;"
                                        v-if="processingData"
                                    />
                                    </button>
                                </div>
                            </div>
                            <div class="imgs-ia-container-img" v-if="originalUploadImageBack && enhancedUploadImageBack">
                                <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}">
                                    <!-- eslint-disable -->
                                    <figure slot="first" class="before">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="originalUploadImageBack">
                                        <figcaption>Before</figcaption>
                                    </figure>
                                    <figure slot="second" class="after">
                                        <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" :src="enhancedUploadImageBack" >
                                        <figcaption>After</figcaption>
                                    </figure>
                                    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                    </svg>
                                    <!-- eslint-enable -->
                                </img-comparison-slider>
                            </div>
                            <div class="px-50 imgs-ia-container-div" v-else>
                                <div v-if="imagePreviewBack" class="preview-container imgs-ia-container-img">
                                    <img :src="imagePreviewBack" :alt="errorChargingImg ? 'Image not available for display' : ''" class="preview-image" ref="imageElement" :style="{ height: computedHeight + 'px' }" @load="adjustHeight" />
                                </div>
                            </div>
                        </div>
                        <div class="imgs-ia-container-img" v-else>
                            <img-comparison-slider class="slider-with-animated-handle" :style="{width: imageWidth + 'px !important'}" v-if="originalImageBack && enhancedImageBack">
                                <!-- eslint-disable -->
                                <figure slot="first" class="before">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/before.webp">
                                    <figcaption>Before</figcaption>
                                </figure>
                                <figure slot="second" class="after">
                                    <img :width="imageWidth+'px !important'" :style="{ height: computedHeight + 'px !important' }" src="https://img-comparison-slider.sneas.io/demo/images/after.webp" >
                                    <figcaption>After</figcaption>
                                </figure>
                                <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                                    <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" fill="#fff" vector-effect="non-scaling-stroke"></path>
                                </svg>
                                <!-- eslint-enable -->
                            </img-comparison-slider>
                        </div>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BTabs, BTab, BImg, BSpinner, BDropdown,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BOverlay, BFormRadio, BFormSelect
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import AspectRatioSelector from "./AspectRatioSelector.vue";
import { ImgComparisonSlider } from 'img-comparison-slider';
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import axios from '@axios'
import { firebase, db, dbErrors, auth } from '@/firebase'

export default {
    components: {
        BSidebar,
        BCard,
        BImg,
        BFormTextarea,
        BSpinner,
        BButton,
        BOverlay,
        BRow,
        BCol,
        BFormSelect,
        BImgLazy,
        BDropdown,

        // external
        ImgComparisonSlider,

        // local
        HeaderSidebar,
        AspectRatioSelector,
    },
    data() {
        return {
            initOpenSidebar: false,
            activeTab: 'generate',
            processingSaveData: false,
            processingData: false,
            actualVisitURL: '',
            shareCopiedUrl: false,
            sourceImage: '',
            editIAImg: false,
            processingDataExistImg: false,
            isErrorImg: false,
            showBoxTab: false,
            errorChargingImg: false,
            suggestIaForm: '',
            userEmail: '',
            promptSendApi: '',
            showPrompt: false,
            // waitingImg: require('@/assets/images/illustration/i-site@2x.png'),
            // waitingImg: require('@/assets/images/pages/auth-v1-top-bg.png'),
            waitingImg: require('@/assets/images/pages/auth-v1-bottom-bg.png'),
            logoIconAIColor: require('@/assets/images/logo/SEOcloud-copilot-icon.svg'),
            logoIconAIDark: require('@/assets/images/logo/SEOcloud-copilot-icon-gray.svg'),

            // track error
            imgValueTrack: 'AI modified',
            optionsTrackImgs: [
                'AI modified',
                'Original'
            ],
            urlImgToModified: '',
            imgValueTrackOriginal: 'Original',
            optionsTrackImgsOriginal: [
                'Original'
            ],

            // reimagine
            reimaginateImage: '',

            // generate
            generatedImage: '',
            promptGenerate: '',

            // PNG
            promptGeneratePNG: '',
            generatedImagePNG: '',

            // upscale
            originalImage: '',
            originalUploadImage: '',
            enhancedImage: '',
            uploadUpscaleTab: true,
            selectedFile: null,
            enhancedUploadImage: '',
            imagePreview: null,

            // background
            originalImageBack: '',
            originalUploadImageBack: '',
            enhancedImageBack: '',
            uploadUpscaleTabBack: true,
            selectedFileBack: null,
            enhancedUploadImageBack: '',
            imagePreviewBack: null,

            // edit
            editTab: 'remover',
            // remover
            imgOriginalRemover: '',
            selectedFileOriginalRemover: '',
            imagePreviewOriginalRemover: '',
            imgMaskRemover: '',
            selectedFileMaskRemover: '',
            imagePreviewMaskRemover: '',
            imgEnhancedRemover: '',
            // resize
            promptResize: '',
            imgOriginalResize: '',
            selectedFileOriginalResize: '',
            imagePreviewOriginalResize: '',
            imgMaskResize: '',
            selectedFileMaskResize: '',
            imagePreviewMaskResize: '',
            imgEnhancedResize: '',

            // options generate text to image
            modelValueGenerate: 'Select',
            optionsModels: [
                // {text: 'Realistic', value: 'realistic'},
                // {text: 'Anime', value: 'anime'},
                // {text: 'Flux-Schnell', value: 'flux-schnell'},
                // {text: 'Flux-Dev', value: 'flux-dev'},
                // {text: 'Flux-Dev-Fast', value: 'flux-dev-fast'},
                // {text: 'SDXL 1.0', value: 'sdxl-1.0'},
                // {text: 'Imagine Turbo', value: 'imagine-turbo'}
                {text: 'Select', value: 'Select'},
                {text: '3d render', value: '3d render'},
                {text: 'Artistic', value: 'Artistic'},
                {text: 'Cartoon', value: 'Cartoon'},
                {text: 'Cinematic', value: 'Cinematic'},
                {text: 'Comic', value: 'Comic'},
                {text: 'Fantasy', value: 'Fantasy'},
                {text: 'Futuristic/sci-fi', value: 'Futuristic/sci-fi'},
                {text: 'Golden hour', value: 'Golden hour'},
                {text: 'Graffiti', value: 'Graffiti'},
                {text: 'Illustration', value: 'Illustration'},
                {text: 'Japanese', value: 'Japanese'},
                {text: 'Light', value: 'Light'},
                {text: 'Light (dark)', value: 'Light (dark)'},
                {text: 'Line drawing', value: 'Line drawing'},
                {text: 'Minimalism', value: 'Minimalism'},
                {text: 'Nature', value: 'Nature'},
                {text: 'Oil painting', value: 'Oil painting'},
                {text: 'pixel art', value: 'pixel art'},
                {text: 'Product', value: 'Product'},
                {text: 'Retro', value: 'Retro'},
                {text: 'Sketch', value: 'Sketch'},
                {text: 'Watercolor', value: 'Watercolor'},
            ],
            aspectRatioVlue: 'Select',
            optionsAspectRatio: [
                'Select',
                '1:1',
                '3:2',
                '4:3',
                '3:4',
                '16:9',
                '9:16'
            ],


            // test
            userData: {},
            isDevSite: false,
            isOpenDelay: false,
            hActiveTab: '',
            timeShow: null,
            loadingChanges: false,
            loadingChangesStrings: false,
            loadingChangesHeadings: false,
            uploadImgs: [],
            processingUploadImg: [],
            loadingImgOrder: false,
            savingIDMI: [],

            // collapse items
            isOpenA: false,
            isOpenTitle: false,
            isOpenDescription: false,
            isOpenMissingImg: false,
            isOpenAlts: false,
            isOpenDeadLink: false,
            isOpenStrings: false,
            isOpenHeadings: false,
            isOpenCanonical: false,
            isOpenGrammar: false,
            isOpenImages: false,

            // tabs
            activeTab: '',
            optionsGemini: [],
            // optionsNova: [],
            optionsBedrockClaude: [],
            optionsBedrockLlama3: [],
            optionsNova: [],
            optionsNovaLoad: [],
            optionsGeminiLoad: [],
            optionsBedrockClaudeLoad: [],
            optionsBedrockLlama3Load: [],
            testVar: '',
            activeItem: 'item0',

            // processSaving
            processSavingDescTitle: [],
            processSavingImgs: [],

            // PROCESS IMG/ALTS
            imgsGeneratedDallE: [],
            processDallESuggest: false,
            promptIMG: '',
            promptALT: '',
            processIMGGPT: [],
            processIMGNova: [],
            processIMGStab: [],
            processALT: [],
            processIMGGPTnxt: [],
            processIMGNovanxt: [],
            processIMGStabnxt: [],
            processALTnxt: [],
            processingIMGGPTnxt: false,
            processingIMGNovanxt: false,
            processingIMGStabnxt: false,
            processingALTnxt: false,
            imagesModifyAI: [],

            // upload images
            filesMI: [],
            filePreviewsMI: [],
            arrayFotosMI: [],
            files: [],
            filePreviews: [],
            arrayFotos: [],

            // variant: 'light',
            opacity: 0.85,
            blur: '2px',
            variant: 'transparent',
            tabActive: 'default',
            tabActiveDesc: 'default',
            tabActiveGem: 'default',
            tabActiveDescGem: 'default',
            tabActiveLoad: 'default',
            tabActiveDescLoad: 'default',
            loadingSideInfo: true,
            loadingSideInfo2: true,
            loadingSideInfo3: true,
            loadingSideInfo4: true,
            loadingSideInfo5: true,
            foundInfo: true,
            itemsBuilds: '',
            cadenaPrompt: [],
            arrayVariables: '',
            optionsChatGPT: [],
            siteIAText: '',
            siteTitle: '',
            siteDescription: '',
            resultsActualPage: [],
            resultsTextPage: [],
            actualURL: '',
            optionsChatGPTErrors: [],
            optionsNovaErrors: [],
            processingData: false,
            idProcessSave: '',
            counterListError: 0,
            refreshIconSuggest: require('@/assets/images/icons/i-refesh.svg'),
            refreshIconSuggestWhite: require('@/assets/images/icons/i-refesh-white.svg'),
            oldText: '',
            changesPageErrors: [],
            errorPageLog: [],
            optionsChatGPTLoad: [],
            optionsChatGPTErrorsLoad: [],
            optionsNovaErrorsLoad: [],
            savingTitles: [],
            savingDescriptions: [],
            savedTitle: [],
            savedDescription: [],
            siteTitleSaved: '',
            siteDescriptionSaved: '',

            // HEADINGS
            arrayH1: [],
            arrayH2: [],

            // conf
            // site
            descriptionSite: '',
            toneSite: '',
            keywordsSite: '',

            // page
            descriptionPage: '',
            tonePage: '',
            keywordsPage: '',

            promptTitle: '',
            promptDescription: '',
            promptSite: '',
            promptPage: '',
            userDataEmail: '',

            processingReloadIA: false,
            processingReloadIA2: false,
            processingReloadIA3: false,
            processingReloadIA4: false,
            processingReloadIA5: false,
            titleManual: '',
            descManual: '',

            // counters
            counterh1Filter: 0,
            counterstFilter: 0,
            counterImFilter: 0,
            counterdLFilter: 0,
            countermAFilter: 0,

            // missing image
            arrayMISuggest: [],
            arrayMISuggestStab: [],
            arrayMISuggestNova: [],
            mISuggest: '',
            savingMI: [],
            savedMI: [],
            activeImg: 0,
            activeTabMI: 'MInova',
            manualMIGPT: '',
            manualMIStab: '',
            manualMINova: '',
            manualIGPT: '',
            manualIStab: '',
            manualINova: '',
            loadingImgs: true,
            loadingImgsStab: true,
            loadingImgsNova: true,

            // missing alt
            arrayMASuggest: [],
            savingMA: [],
            savedMA: [],
            activeAlt: 0,
            manualAlt: '',

            // strings
            arrayStrings: [],

            // dlinks
            arrayDLs: [],

            // lazy
            hasReached700px: false,
            hasReached700pxDL: false,
            hasReached700pxStr: false,
            arrayMASuggestLazy: [],
            arrayMISuggestLazy: [],
            arrayDLsLazy: [],
            arrayStringsLazy: [],

            // canonicals
            urlCanonical: '',

            // collapse
            titleGPTOpen: false,
            descGPTOpen: false,
            MIGPTOpen: false,
            IMGGPTOpen: false,
            AltGPTOpen: false,
            CanGPTOpen: false,
            StrGPTOpen: false,
            DlGPTOpen: false,
            GrammGPTOpen: false,
            HGPTOpen: false,

            // collapse
            titleNovaOpen: false,
            descNovaOpen: false,
            MINovaOpen: false,
            IMGNovaOpen: false,
            AltNovaOpen: false,
            CanNovaOpen: false,
            StrNovaOpen: false,
            DlNovaOpen: false,
            GrammNovaOpen: false,
            HNovaOpen: false,

            titleGeminiOpen: false,
            descGeminiOpen: false,
            MIGeminiOpen: false,
            IMGGeminiOpen: false,
            AltGeminiOpen: false,
            CanGeminiOpen: false,
            StrGeminiOpen: false,
            DlGeminiOpen: false,
            GrammGeminiOpen: false,
            HGeminiOpen: false,

            titleClaudeOpen: false,
            descClaudeOpen: false,
            MIClaudeOpen: false,
            IMGClaudeOpen: false,
            AltClaudeOpen: false,
            CanClaudeOpen: false,
            StrClaudeOpen: false,
            DlClaudeOpen: false,
            GrammClaudeOpen: false,
            HClaudeOpen: false,

            titleLlamaOpen: false,
            descLlamaOpen: false,
            MILlamaOpen: false,
            IMGLlamaOpen: false,
            AltLlamaOpen: false,
            CanLlamaOpen: false,
            StrLlamaOpen: false,
            DlLlamaOpen: false,
            GrammLlamaOpen: false,
            HLlamaOpen: false,

            // dinamic image height width
            computedHeight: 300,
            imageWidth: 0
        }
    },
    model: {
        prop: 'showOpenEditImage',
        event: 'update:show-open-edit-image',
    },
    props: ['showOpenEditImage','SiteID','infoIA','IASiteSelected','logicOwner','crawlID','sitesProPages','changeerror','siteObj','siteHomepage','domainPrefix','optionsStrings','orderError','idErrorSelect','objErrImg'],
    watch: {
        showOpenEditImage(val) {
            this.isErrorImg = true
            this.errorChargingImg = false
            this.initOpenSidebar = false
            this.showPrompt = false
            this.imgValueTrack = 'AI modified'
            this.urlImgToModified = ''
            this.processingSaveData = false
            // console.log(this.objErrImg)

            if(!val) {
                document.body.style.overflow = 'auto'
                this.generatedImage = ''
                this.promptGenerate = ''
                this.promptGeneratePNG = ''
                this.generatedImagePNG = ''
                this.originalImage = ''
                this.originalUploadImage = ''
                this.enhancedImage = ''
                this.activeTab = 'generate'
                this.uploadUpscaleTab = true
                this.selectedFile = null
                this.enhancedUploadImage = ''
                this.imagePreview = null
                this.editTab = 'remover'
                this.imgOriginalRemover = ''
                this.selectedFileOriginalRemover = ''
                this.imagePreviewOriginalRemover = ''
                this.imgMaskRemover = ''
                this.selectedFileMaskRemover = ''
                this.imagePreviewMaskRemover = ''
                this.imgEnhancedRemover = ''
                this.promptResize = ''
                this.imgOriginalResize = ''
                this.selectedFileOriginalResize = ''
                this.imagePreviewOriginalResize = ''
                this.imgMaskResize = ''
                this.selectedFileMaskResize = ''
                this.imagePreviewMaskResize = ''
                this.imgEnhancedResize = ''
            } else {
                document.body.style.overflow = 'hidden'
                this.activeTab = 'generate'
                this.sourceImage = ''
                this.editIAImg = false
                this.processingDataExistImg = false
                var urlWindow = window.location.href
                var urlWindowTransform = new URL(urlWindow)
                var urlWindowDomain = urlWindowTransform.hostname
                var urlWindowProtocol = urlWindowTransform.protocol
                this.actualVisitURL = urlWindowProtocol+'//'+urlWindowDomain

                const user = auth.currentUser
                if(user) {
                    db.collection('Users').doc(user.uid).get()
                    .then(userDoc => {
                        if(userDoc.exists) {
                            this.userEmail = userDoc.data().email
                        }
                    })
                }

                // console.log(this.objErrImg)
                if(!this.objErrImg.exist && this.objErrImg.img) {
                    // an alternative for this image https://src.seocloud.ai/siteImg/1739581845371DallE
                    // this.generateVariation(this.objErrImg.img)
                    if(this.objErrImg.initImg) {
                        this.checkImageStatus(this.objErrImg.initImg)
                        // console.log('status init img: ', statusInitImg);
                        // this.optionsTrackImgs = [
                        //     'AI modified',
                        //     'Original (not found)'
                        // ]
                    }

                    this.setExistImage(this.objErrImg.img)
                }
                this.urlImgToModified = this.objErrImg.img

                if(this.objErrImg.exist) {
                    this.isErrorImg = true
                    this.activeTab = 'generate'
                    this.showBoxTab = true
                } else {
                    this.isErrorImg = false
                }

                var typeBuild = 'mI'
                var itemsBuilds = []
                var itemsBuildsCache = []
                var cadenaPrompt = []
                var arrayVariables = []

                dbErrors.collection('IA_form_builder').doc('prompt').get()
                .then(docPrompt => {
                    var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                    dbErrors.collection('IA_form_builder').doc('errors').get()
                    .then(docBuilder => {
                        if(docBuilder.data().form) {
                            itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                            itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                        }

                        if(promtObj.length >= 1) {
                            const cadena = promtObj[0].logic
                            cadenaPrompt = cadena

                            // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                            const resultados = cadena.match(/\((.*?)\)/g)

                            if(resultados) {
                                const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                                arrayVariables = palabrasEntreParentesis
                                // console.log(palabrasEntreParentesis)
                                if(palabrasEntreParentesis.includes('web_content')) {
                                    itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                                }
                            } else {
                                console.log("No se encontró variables en el prompt.")
                            }
                        } else {
                            cadenaPrompt = []
                            arrayVariables = []
                        }

                        var responseObjArray = []
                        var urlT = new URL(this.IASiteSelected[0].cUrl)
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        buildR.forEach(ele => {
                            ele.textField = this.siteTitleSaved ? this.siteTitleSaved : this.siteTitle ? this.siteTitle : urlT.pathname
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                            
                            var textLimitFixAI = this.limitarPalabras((this.infoIaSite[0].textsite), 240)
                            
                            ele.textField = textLimitFixAI
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                        buildR.forEach(ele => {
                            ele.textField = this.IASiteSelected[0].cUrl
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                        buildR.forEach(ele => {
                            ele.textField = this.objErrImg.img
                        })

                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                            arrayFieldsAnswer.forEach((item, index) => {
                                if(item.variableField === ele) {
                                    var responseField = ''
                                    if(item.typeField === 'checkbox') {
                                        var variableCounter = 0
                                        item.textField.forEach(ele => {
                                            variableCounter += 1
                                            responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                        })
                                    } else {
                                        responseField = item.textField
                                    }

                                    responseObjArray.push({response: responseField})
                                }
                            })
                        })

                        if(cadenaPrompt.length >= 1) {
                            // this.showDelayGPT = true
                            let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                            let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                            const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                            var counterFor = 0
                            var sendToApi = ''
                            partesNoParentesis.forEach(element => {
                                if(responseObjArray[counterFor]) {
                                    sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                                    counterFor += 1
                                } else {
                                    sendToApi += element
                                }
                            })
                            // console.log(sendToApi)
                            this.suggestIaForm = sendToApi
                        }
                    })
                })
            }
        },
        activeTab(val) {
            // if(val === 'reimagine') {
            //     this.reimaginateImage = ''
            //     if(!this.objErrImg.exist && this.objErrImg.img) {
            //         this.generateReimagine(this.objErrImg.img)
            //     } else {
            //         this.generateReimaginePrompt()
            //     }
            // }
        },
        showBoxTab(val) {
            this.initOpenSidebar = true
        },
        imgValueTrack(val) {
            // console.log(val)
            if(this.getStatusTab()) {
                this.confirmationChangeTab(this.activeTab, true)
            } else {
                if(val === 'Original') {
                    this.urlImgToModified = this.objErrImg.initImg
                    this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                } else if(val === 'Original (not found)') {
                    this.isErrorImg = true
                    this.urlImgToModified = this.objErrImg.initImg
                    // this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                    this.sourceImage = ''
                    this.originalUploadImage = ''
                    this.imgOriginalRemover = ''
                    this.imgOriginalResize = ''
                    this.originalUploadImageBack = ''

                    this.generatedImage = ''
                    this.generatedImagePNG = ''
                    this.enhancedUploadImage = ''
                    this.imgEnhancedRemover = ''
                    this.imgEnhancedResize = ''
                    this.enhancedUploadImageBack = ''
                    this.reimaginateImage = ''
                } else {
                    this.urlImgToModified = this.objErrImg.img
                    this.setExistImage(this.objErrImg.img)
                }
            }
        }
    },
    methods: {
        showHidePrompt() {
            if(this.showPrompt) {
                this.showPrompt = false
            } else {
                this.showPrompt = true
            }
        },
        logicReimagine() {
            if(this.activeTab === 'reimagine') {
                // this.reimaginateImage = ''
                if(this.editIAImg) {
                    if(this.sourceImage) {
                        this.generateReimagine(this.sourceImage, true)
                    }
                } else {
                    if(!this.objErrImg.exist && this.objErrImg.img) {
                        this.generateReimagine((this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img), false)
                    } else {
                        this.generateReimaginePrompt()
                    }
                }
            }
        },
        closeSidebar() {
            this.$refs.sidebar_edit_image.hide()
        },
        limitarPalabras(texto, limite) {
            const palabras = texto.split(' '); // Divide el texto en palabras
            return palabras.slice(0, limite).join(' '); // Toma las primeras `limite` palabras y únelas
        },
        getStatusTab() {
            if(this.activeTab === 'generate' && this.generatedImage) {
                return true
            } else if(this.activeTab === 'reimagine' && this.reimaginateImage) {
                return true
            } else if(this.activeTab === 'upscale' && this.enhancedUploadImage) {
                return true
            } else if(this.activeTab === 'remover' && this.imgEnhancedRemover) {
                return true
            } else if(this.activeTab === 'resize' && this.imgEnhancedResize) {
                return true
            } else if(this.activeTab === 'background' && this.enhancedUploadImageBack) {
                return true
            }

            return false
        },
        getImageWidth() {
            // ref="imageElementRender" @load="getImageWidth"
            const img = this.$refs.imageElement
            if (img) {
                this.imageWidth = img.clientWidth // Guarda el ancho real de la imagen
                // console.log("Ancho de la imagen:", this.imageWidth) // Para verificar en consola
            }
        },
        adjustHeight() {
            // Obtener la altura real de la imagen
            const img = this.$refs.imageElement
            // console.log(img)
            if(img) {
                const originalHeight = img.naturalHeight
                
                // Aplicar la multiplicación según la condición
                // if (originalHeight * 3 <= 840) {
                //     this.computedHeight = originalHeight * 3
                //     this.getImageWidth()
                // } else {
                //     this.computedHeight = originalHeight * 2
                //     this.getImageWidth()
                // }
                // console.log(originalHeight)
                
                let newHeight = originalHeight * 3
                if(originalHeight >= 230) {
                    newHeight = originalHeight
                } else if(originalHeight > 100) {
                    newHeight = originalHeight * 2
                }

                //  else if(originalHeight > 840) {
                //     newHeight = originalHeight * 2
                // }

                // Limitar la altura máxima a 844px
                newHeight = Math.min(newHeight, 500)
                this.computedHeight = newHeight
                this.getImageWidth()
            }
        },
        handleRatioChange(newRatio) {
            // console.log(newRatio)
            this.aspectRatioVlue = newRatio
        },
        getPathURLImg(val) {
            if(!val) return ''
            // console.log(val)
            var getPath = new URL(val)
            return getPath.pathname
        },
        initImgFound(url) {
            this.optionsTrackImgs = [
                'AI modified',
                'Original'
            ]
        },
        initImgNotFound(url) {
            this.optionsTrackImgs = [
                'AI modified',
                'Original (not found)'
            ]
        },
        checkImageStatus(url) {
            new Promise((resolve) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(this.initImgFound(url));
                img.onerror = () => resolve(this.initImgNotFound(url));
            });
        },
        async fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(",")[1]); // Quitamos el prefijo "data:image/png;base64,"
                reader.onerror = (error) => reject(error);
            });
        },
        blobUrlToBase64(blobUrl) {
            return new Promise((resolve, reject) => {
                fetch(blobUrl)
                    .then(response => response.blob()) // Convertimos la URL blob en un archivo Blob
                    .then(blob => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = () => resolve(reader.result.split(",")[1]); // Quitamos el prefijo "data:image/png;base64,"
                        reader.onerror = (error) => reject(error);
                    })
                    .catch(error => reject(error));
            });
        },
        base64ToBlob(base64, mimeType) {
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let i = 0; i < byteCharacters.length; i += 512) {
                const slice = byteCharacters.slice(i, i + 512);
                const byteNumbers = new Array(slice.length);
                for (let j = 0; j < slice.length; j++) {
                    byteNumbers[j] = slice.charCodeAt(j);
                }
                byteArrays.push(new Uint8Array(byteNumbers));
            }

            return new Blob(byteArrays, { type: mimeType });
        },
        async urlToFile(imageUrl, fileName = "image2.png", mimeType = "image/png") {
            // const corsProxy = "https://corsproxy.io/?";
            // const imageUrlx = imageUrl
            // const proxiedUrl = corsProxy + encodeURIComponent(imageUrlx);
            const response = await fetch(imageUrl);
            // console.log(response)
            const blob = await response.blob();
            return new File([blob], fileName, { type: mimeType });
            // const response = await fetch(imageUrl);
            // const blob = await response.blob();
            
            // return new Promise((resolve, reject) => {
            //     const reader = new FileReader();
            //     reader.onloadend = () => {
            //         const base64data = reader.result.split(",")[1]; // Eliminar el encabezado `data:image/png;base64,`
            //         const byteCharacters = atob(base64data);
            //         const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
            //         const byteArray = new Uint8Array(byteNumbers);
            //         const file = new File([byteArray], fileName, { type: mimeType });
            //         resolve(file);
            //     };
            //     reader.onerror = reject;
            //     reader.readAsDataURL(blob);
            // });
        },
        async urlToFileFromBackend(imageUrl, fileName = "image.png", mimeType = "image/png") {
            const response = await fetch(`https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/download-image?url=${encodeURIComponent(imageUrl)}`);
            // console.log(response)
            const blob = await response.blob();
            return new File([blob], fileName, { type: mimeType });
        },
        async resizeImageFileLocal(file, maxWidth = 4096, maxHeight = 4096) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    let { width, height } = img;

                    // Asegurar que la altura esté dentro del rango permitido
                    if (height < 320) height = 320;
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }

                    if (width < 320) width = 320;
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }

                    // Redibujar la imagen en un canvas
                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name, { type: file.type }));
                    }, file.type);
                };
                img.onerror = (error) => reject(error);
            });
        },
        async resizeImageFileLocalBlob(file, maxWidth = 4096, maxHeight = 4096) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                const reader = new FileReader();

                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(file);

                img.onload = () => {
                    let { width, height } = img;

                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }

                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }

                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convertimos la imagen a PNG Base64
                    const base64 = canvas.toDataURL("image/png").split(",")[1]; 
                    resolve(base64);
                };

                img.onerror = (error) => reject(error);
            });
        },
        async fetchBlobData(blobUrl) {
            const response = await fetch(blobUrl);
            const blob = await response.blob();
            
            // Validar el tipo de archivo
            if (!blob.type.startsWith('image/')) {
                throw new Error('El archivo no es una imagen válida');
            }

            const file = new File([blob], 'image.png', { type: 'image/png' });
            return file;
        },
        async convertBlobToBase64(blobUrl, maxWidth = 4096, maxHeight = 4096) {
            try {
                // const file = await this.fetchBlobData(blobUrl);
                // return await this.resizeImageFileLocalBlob(file, maxWidth, maxHeight);

                const blob = new Blob([blobUrl], { type: "image/png" });
                // Crear un archivo desde el Blob
                const file = new File([blob], "image2.png", { type: "image/png" });
                return file;
            } catch (error) {
                console.error('Error al convertir Blob a Base64:', error);
            }
        },
        async convertBlobToJPG(blob, quality = 0.8) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        // Ajustar el tamaño del canvas
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        // Convertimos el canvas a JPG
                        canvas.toBlob(
                            (jpgBlob) => {
                                if (jpgBlob) {
                                    resolve(new File([jpgBlob], "converted.jpg", { type: "image/jpeg" }));
                                } else {
                                    reject("Error al convertir el Blob a JPG");
                                }
                            },
                            "image/jpeg",
                            quality
                        );
                    };
                    img.onerror = (err) => reject(err);
                };
                reader.onerror = (err) => reject(err);
            });
        },

        // generate variation
        async setExistImage(imageUrl) {
            this.processingData = true
            this.processingDataExistImg = true
            // const formData = new FormData();
            // formData.append("image", this.selectedFileBack);

            try {
                const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");
                const resizedFile = await this.resizeImageFileLocal(file);
                const imageBase64 = await this.fileToBase64(resizedFile);

                // const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas-variation", { image: imageBase64 }, {
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                // });

                // // console.log(response.data)
                // const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                // // Convertir Base64 a Blob
                // const blob = this.base64ToBlob(base64Image, "image/png");
                // // Crear URL para mostrar la imagen
                // const imageUrlEnhanced = URL.createObjectURL(blob);
                // // console.log(imageUrl)

                // this.generatedImage = imageUrlEnhanced
                // this.generatedImagePNG = imageUrlEnhanced
                // this.enhancedUploadImage = imageUrlEnhanced
                // this.imgEnhancedRemover = imageUrlEnhanced
                // this.imgEnhancedResize = imageUrlEnhanced
                // this.enhancedUploadImageBack = imageUrlEnhanced
                this.sourceImage = imageUrl

                this.selectedFile = file
                this.originalUploadImage = imageUrl
                this.imagePreview = imageUrl

                this.selectedFileMaskRemover = file
                this.imgOriginalRemover = imageUrl
                this.imagePreviewOriginalRemover = imageUrl

                this.selectedFileMaskResize = file
                this.imgOriginalResize = imageUrl
                this.imagePreviewOriginalResize = imageUrl

                this.selectedFileBack = file
                this.originalUploadImageBack = imageUrl
                this.imagePreviewBack = imageUrl
                
                this.processingData = false
                this.processingDataExistImg = false
                this.showBoxTab = false
                this.errorChargingImg = false

                this.generatedImage = ''
                this.generatedImagePNG = ''
                this.enhancedUploadImage = ''
                this.imgEnhancedRemover = ''
                this.imgEnhancedResize = ''
                this.enhancedUploadImageBack = ''
                this.reimaginateImage = ''
            } catch (error) {
                this.processingData = false
                this.processingDataExistImg = false
                this.showBoxTab = false
                this.errorChargingImg = true
                console.error("Error subiendo la imagen set:", error);
                // this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: `Error: Please try again!`,
                //         icon: 'BellIcon',
                //         variant: 'warning',
                //     },
                // })
            }
        },
        async generateVariation(imageUrl) {
            this.processingData = true
            this.processingDataExistImg = true
            // const formData = new FormData();
            // formData.append("image", this.selectedFileBack);

            try {
                const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");
                const resizedFile = await this.resizeImageFileLocal(file);
                const imageBase64 = await this.fileToBase64(resizedFile);

                const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas-variation", { image: imageBase64 }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                // console.log(response.data)
                const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                // Convertir Base64 a Blob
                const blob = this.base64ToBlob(base64Image, "image/png");
                // Crear URL para mostrar la imagen
                const imageUrlEnhanced = URL.createObjectURL(blob);
                // console.log(imageUrl)

                this.generatedImage = imageUrlEnhanced
                this.generatedImagePNG = imageUrlEnhanced
                this.enhancedUploadImage = imageUrlEnhanced
                this.imgEnhancedRemover = imageUrlEnhanced
                this.imgEnhancedResize = imageUrlEnhanced
                this.enhancedUploadImageBack = imageUrlEnhanced

                this.sourceImage = imageUrl

                this.selectedFile = file
                this.originalUploadImage = imageUrl
                this.imagePreview = imageUrl

                this.selectedFileMaskRemover = file
                this.imgOriginalRemover = imageUrl
                this.imagePreviewOriginalRemover = imageUrl

                this.selectedFileMaskResize = file
                this.imgOriginalResize = imageUrl
                this.imagePreviewOriginalResize = imageUrl

                this.selectedFileBack = file
                this.originalUploadImageBack = imageUrl
                this.imagePreviewBack = imageUrl
                
                this.processingData = false
                this.processingDataExistImg = false
                this.showBoxTab = false
                this.errorChargingImg = false
            } catch (error) {
                this.processingData = false
                this.processingDataExistImg = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen var:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // reimagine
        async generateReimagine(imageUrl, isApplyIMG) {
            this.processingData = true

            try {
                if(isApplyIMG) {
                    // const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");
                    // const resizedFile = await this.resizeImageFileLocal(file);
                    // const imageBase64 = await this.fileToBase64(resizedFile);
                    const imageBase64 = await this.blobUrlToBase64(imageUrl)

                    const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas-variation", { image: imageBase64 }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    // console.log(response.data)
                    const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                    // Convertir Base64 a Blob
                    const blob = this.base64ToBlob(base64Image, "image/png");
                    // Crear URL para mostrar la imagen
                    const imageUrlEnhanced = URL.createObjectURL(blob);
                    // console.log(imageUrl)
                    
                    this.reimaginateImage = imageUrlEnhanced
                    // this.selectedFile = file
                    this.processingData = false
                    this.showBoxTab = false
                    this.errorChargingImg = false
                    // this.isErrorImg = false
                } else {
                    const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");
                    const resizedFile = await this.resizeImageFileLocal(file);
                    const imageBase64 = await this.fileToBase64(resizedFile);

                    const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas-variation", { image: imageBase64 }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    // console.log(response.data)
                    const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                    // Convertir Base64 a Blob
                    const blob = this.base64ToBlob(base64Image, "image/png");
                    // Crear URL para mostrar la imagen
                    const imageUrlEnhanced = URL.createObjectURL(blob);
                    // console.log(imageUrl)
                    
                    this.reimaginateImage = imageUrlEnhanced
                    // this.selectedFile = file
                    this.processingData = false
                    this.showBoxTab = false
                    this.errorChargingImg = false
                    // this.isErrorImg = false
                }
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen reimage:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },
        async generateReimaginePrompt() {
            this.processingData = true
            // if(!this.selectedFile) {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: `Error: Select a file first`,
            //             icon: 'BellIcon',
            //             variant: 'warning',
            //         },
            //     })
            //     return;
            // }
            // var convertModel = 'realistic'
            // console.log(convertModel,this.aspectRatioVlue)
            
            const formData = new FormData();
            formData.append("prompt", (`I want a suggestion image for this page ${this.IASiteSelected[0].cUrl}`));
            formData.append("style", 'realistic');
            formData.append("aspect_ratio", '1:1');
            formData.append("seed", "5");

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);

                const response = await this.axios.post("https://api.vyro.ai/v2/image/generations", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.reimaginateImage = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                // this.isErrorImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen reimage:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // generate
        async generateImage() {
            this.processingData = true
            // if(!this.selectedFile) {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: `Error: Select a file first`,
            //             icon: 'BellIcon',
            //             variant: 'warning',
            //         },
            //     })
            //     return;
            // }
            var convertModel = this.modelValueGenerate && this.modelValueGenerate.value ? (this.modelValueGenerate.value === 'Select' ? 'realistic' : this.modelValueGenerate.value) : this.modelValueGenerate ? (this.modelValueGenerate === 'Select' ? 'realistic' : this.modelValueGenerate) : 'realistic'
            // console.log(convertModel,this.aspectRatioVlue)
            
            const formData = new FormData();
            formData.append("prompt", (`${this.promptGenerate} with a ${convertModel} design style`));
            formData.append("style", 'realistic');
            formData.append("aspect_ratio", this.aspectRatioVlue === 'Select' ? '1:1' : this.aspectRatioVlue);
            formData.append("seed", "5");

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);
                this.promptSendApi = `${this.promptGenerate} with a ${convertModel} design style`

                const response = await this.axios.post("https://api.vyro.ai/v2/image/generations", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.generatedImage = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                this.isErrorImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen gen:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // generate png
        async generateImagePNG() {
            this.processingData = true
            // if(!this.selectedFile) {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: `Error: Select a file first`,
            //             icon: 'BellIcon',
            //             variant: 'warning',
            //         },
            //     })
            //     return;
            // }

            const formData = new FormData();
            formData.append("prompt", this.promptGeneratePNG);
            // formData.append("style", "realistic");
            formData.append("aspect_ratio", "1:1");
            formData.append("seed", "5");

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);

                const response = await this.axios.post("https://api.vyro.ai/v2/image/generations/transparent", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.generatedImagePNG = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen pn:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // upscale
        upscaleImage() {
            this.activeTab = 'upscale'
            this.uploadUpscaleTab = true
        },
        upscaleCurrentImage() {
            this.uploadUpscaleTab = false
        },
        handleFileUpload(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFile = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.originalUploadImage = URL.createObjectURL(file);
            this.imagePreview = URL.createObjectURL(file);
        },
        async uploadImageUpscale() {
            this.processingData = true

            if(!this.selectedFile) {
                this.processingData = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Select a file first`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
                return;
            }

            const formData = new FormData();
            formData.append("image", this.selectedFile);

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);

                const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.enhancedUploadImage = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen ups:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // background
        upscaleImageBack() {
            this.activeTab = 'background'
            this.uploadUpscaleTabBack = true
        },
        upscaleCurrentImageBack() {
            this.uploadUpscaleTabBack = false
        },
        handleFileUploadBack(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFileBack = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.originalUploadImageBack = URL.createObjectURL(file);
            this.imagePreviewBack = URL.createObjectURL(file);
        },
        async uploadImageBackground() {
            this.processingData = true

            if(!this.selectedFileBack) {
                this.processingData = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Select a file first`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
                return;
            }

            // const formData = new FormData();
            // formData.append("image", this.selectedFileBack);

            try {
                // const imageBase64 = await this.fileToBase64(this.selectedFileBack);
                // const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");
                if(this.originalUploadImageBack && this.originalUploadImageBack.includes('blob:')) {
                    const resizedFile = await this.resizeImageFileLocal(this.selectedFileBack);
                    const imageBase64 = await this.fileToBase64(resizedFile);

                    // const imageBase64 = await this.resizeImageFileLocalBlob(this.selectedFileBack);

                    
                    // const resizedFile = await this.fetchBlobData(this.imagePreviewBack);
                    // const resizedFileC = await this.convertBlobToBase64(this.imagePreviewBack);
                    // const resizedFile = await this.resizeImageFileLocal(resizedFileC);
                    // const imageBase64 = await this.fileToBase64(resizedFile);
                    // console.log(resizedFile,imageBase64)
                    // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                    //     headers: {
                    //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                    //         "Content-Type": "multipart/form-data",
                    //     },
                    // });

                    // console.log("Res:", response.data);
                    // console.log(this.imagePreviewBack,imageBase64)

                    const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas", { image: imageBase64 }, {
                        headers: {
                            // "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                            "Content-Type": "application/json",
                        },
                        // responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                    });

                    // console.log(response.data)

                    const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                    // Convertir Base64 a Blob
                    const blob = this.base64ToBlob(base64Image, "image/png");
                    // Crear URL para mostrar la imagen
                    const imageUrl = URL.createObjectURL(blob);
                    // console.log(imageUrl)

                    // Convertir la respuesta en un Blob y crear una URL para mostrarla
                    // const blob = new Blob([response.data], { type: "image/png" });
                    this.enhancedUploadImageBack = imageUrl
                    this.processingData = false
                    this.showBoxTab = false
                    this.errorChargingImg = false
                } else {
                    const resizedFile = await this.resizeImageFileLocal(this.selectedFileBack);
                    const imageBase64 = await this.fileToBase64(resizedFile);
                    // console.log(imageBase64)
                    // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                    //     headers: {
                    //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                    //         "Content-Type": "multipart/form-data",
                    //     },
                    // });

                    // console.log("Res:", response.data);
                    // console.log(imageBase64)

                    const response = await this.axios.post("https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-nova-canvas", { image: imageBase64 }, {
                        headers: {
                            // "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                            "Content-Type": "application/json",
                        },
                        // responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                    });

                    // console.log(response.data)

                    const base64Image = response.data.response; // Asegúrate de que esta es la clave correcta en la respuesta
                    // Convertir Base64 a Blob
                    const blob = this.base64ToBlob(base64Image, "image/png");
                    // Crear URL para mostrar la imagen
                    const imageUrl = URL.createObjectURL(blob);
                    // console.log(imageUrl)

                    // Convertir la respuesta en un Blob y crear una URL para mostrarla
                    // const blob = new Blob([response.data], { type: "image/png" });
                    this.enhancedUploadImageBack = imageUrl
                    this.processingData = false
                    this.showBoxTab = false
                    this.errorChargingImg = false
                }
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen bkg:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // edit
        // remover
        handleFileUploadOriginalRemover(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFileOriginalRemover = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.imgOriginalRemover = URL.createObjectURL(file);
            this.imagePreviewOriginalRemover = URL.createObjectURL(file);
        },
        handleFileUploadMaskRemover(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFileMaskRemover = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.imgMaskRemover = URL.createObjectURL(file);
            this.imagePreviewMaskRemover = URL.createObjectURL(file);
        },
        async uploadImageRemover() {
            this.processingData = true

            if(!this.selectedFileOriginalRemover || !this.selectedFileMaskRemover) {
                this.processingData = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Select a file first`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
                return;
            }

            const formData = new FormData();
            formData.append("image", this.selectedFileOriginalRemover);
            formData.append("mask", this.selectedFileMaskRemover);

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);

                const response = await this.axios.post("https://api.vyro.ai/v2/image/edits/remove", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.imgEnhancedRemover = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen rem:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },
        // resize
        handleFileUploadOriginalResize(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFileOriginalResize = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.imgOriginalResize = URL.createObjectURL(file);
            this.imagePreviewOriginalResize = URL.createObjectURL(file);
        },
        handleFileUploadMaskResize(event) {
            // this.selectedFile = event.target.files[0];
            const file = event.target.files[0];
            if (!file) return;

            this.selectedFileMaskResize = file;

            // Convertir la imagen seleccionada en una URL para mostrarla antes de enviarla
            this.imgMaskResize = URL.createObjectURL(file);
            this.imagePreviewMaskResize = URL.createObjectURL(file);
        },
        async uploadImageResize() {
            this.processingData = true

            if(!this.selectedFileOriginalResize || !this.selectedFileMaskResize || !this.promptResize) {
                this.processingData = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Select a file first`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
                return;
            }

            const formData = new FormData();
            formData.append("prompt", this.promptResize);
            formData.append("image", this.selectedFileOriginalResize);
            formData.append("mask", this.selectedFileMaskResize);

            try {
                // const response = await this.axios.post("https://api.vyro.ai/v2/image/enhance", formData, {
                //     headers: {
                //         "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                //         "Content-Type": "multipart/form-data",
                //     },
                // });

                // console.log("Res:", response.data);
                this.promptSendApi = `${this.promptResize}`

                const response = await this.axios.post("https://api.vyro.ai/v2/image/edits/ai-resize", formData, {
                    headers: {
                        "Authorization": "Bearer vk-B32233DugGJJoewwooTGyuRF72YsfkBUFNxF92xUgw2CBqNO4",
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer", // Recibir la imagen mejorada como binario
                });

                // Convertir la respuesta en un Blob y crear una URL para mostrarla
                const blob = new Blob([response.data], { type: "image/png" });
                this.imgEnhancedResize = URL.createObjectURL(blob);
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
            } catch (error) {
                this.processingData = false
                this.showBoxTab = false
                this.errorChargingImg = false
                console.error("Error subiendo la imagen rez:", error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Error: Please try again!`,
                        icon: 'BellIcon',
                        variant: 'warning',
                    },
                })
            }
        },

        // edit generated img
        async loadImgIAGenerated() {
            const imageUrl = this.activeTab === 'reimagine' ? this.reimaginateImage : this.activeTab === 'generate' ? this.generatedImage : this.activeTab === 'upscale' ? this.enhancedUploadImage : this.activeTab === 'resize' ? this.imgEnhancedResize : this.activeTab === 'remover' ? this.imgEnhancedRemover : this.enhancedUploadImageBack
            const file = await this.urlToFile(imageUrl, "converted-image2.png");
            // console.log(imageUrl)
            // const file = await this.urlToFileFromBackend(imageUrl, "converted-image.png");

            this.editIAImg = true

            // https://src.seocloud.ai/waiting.png
            this.generatedImage = ''
            this.generatedImagePNG = ''
            this.enhancedUploadImage = ''
            this.imgEnhancedRemover = ''
            this.imgEnhancedResize = ''
            this.enhancedUploadImageBack = ''
            this.reimaginateImage = ''
            // console.log(imageUrl)

            this.sourceImage = imageUrl

            this.selectedFile = file
            this.originalUploadImage = imageUrl
            this.imagePreview = imageUrl

            this.selectedFileMaskRemover = file
            this.imgOriginalRemover = imageUrl
            this.imagePreviewOriginalRemover = imageUrl

            this.selectedFileMaskResize = file
            this.imgOriginalResize = imageUrl
            this.imagePreviewOriginalResize = imageUrl

            this.selectedFileBack = file
            this.originalUploadImageBack = imageUrl
            this.imagePreviewBack = imageUrl

            this.showBoxTab = false
            this.errorChargingImg = false
        },

        // save
        async saveImg(payload,type) {
            this.processingSaveData = true
            this.showBoxTab = false
            this.errorChargingImg = false
            // var file = this.filePreviewsMI.filter(ele => ele.id === payload.idError)
            // console.log(payload.idError,this.filePreviewsMI,file.length)
            // if(file.length) {
                this.processingUploadImg.push(payload.idError)
                // const refImgF = storage.ref().child('copilot-imgs').child(file[0].file.name);
                // const resF = await refImgF.put(file[0].file);
                // const urlDescargaF = await refImgF.getDownloadURL();
                var enhanced = ''
                if(type === 'generate') {
                    enhanced = this.generatedImage
                } else if(type === 'reimagine') {
                    enhanced = this.reimaginateImage
                } else if(type === 'generatePNG') {
                    enhanced = this.generatedImagePNG
                } else if(type === 'upscale') {
                    enhanced = this.enhancedUploadImage
                } else if(type === 'remover') {
                    enhanced = this.imgEnhancedRemover
                } else if(type === 'resize') {
                    enhanced = this.imgEnhancedResize
                } else if(type === 'background') {
                    enhanced = this.enhancedUploadImageBack
                }

                if(this.editIAImg && !enhanced) {
                    if(type === 'generate') {
                        enhanced = this.sourceImage
                    } else if(type === 'reimagine') {
                        enhanced = this.sourceImage
                    } else if(type === 'generatePNG') {
                        enhanced = this.sourceImage
                    } else if(type === 'upscale') {
                        enhanced = this.imagePreview
                    } else if(type === 'remover') {
                        enhanced = this.imagePreviewOriginalRemover
                    } else if(type === 'resize') {
                        enhanced = this.imagePreviewOriginalResize
                    } else if(type === 'background') {
                        enhanced = this.imagePreviewBack
                    }
                }

                const responseFe = await fetch(enhanced);
                const blob = await responseFe.blob();

                // const jpgFile = await this.convertBlobToJPG(blob);
                // console.log("Imagen convertida:", jpgFile);

                // Crear una URL para mostrar la imagen en la página
                // const imageUrl = URL.createObjectURL(jpgFile);
                // console.log("URL de la imagen JPG:", imageUrl);

                const storageRef = firebase.storage().ref();
                const fileName = `enhanced-${Date.now()}.png`;
                const fileRef = storageRef.child(`copilot-imgs/${fileName}`);
                await fileRef.put(blob);
                const urlDescargaF = await fileRef.getDownloadURL();
                // console.log(urlDescargaF)

                var obj = {
                    url: urlDescargaF
                }
                var config = {
                    method: 'POST',
                    url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
                    maxBodyLength: Infinity,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(obj),
                }

                this.axios(config)
                .then(async response => {

                    var img = response.data

                    try {
                        var urlC = this.IASiteSelected && this.IASiteSelected.length ? this.IASiteSelected[0].cUrl : ''
                        var convURL = new URL(urlC)
                        // const nameFileSave = `${Date.now()}DallE`;
                        const nameFileSave = `${convURL.hostname ? convURL.hostname : 'enhanced'}${Date.now()}${type === 'background' || type === 'generatePNG' ? '.png' : '.jpg'}`
                        const result = await axios.post(
                            'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/save-img/src-seo', // URL de tu función
                            {
                                fileData: img, // Base64 del archivo
                                fileName: `${nameFileSave}`, // Ruta del archivo en el bucket
                                fileBucket: 'siteImg'
                            }
                        );

                        // console.log('Archivo subido y público:', result.data.url)

                        if(result.data.url) {
                            const urlDescarga = result.data.url
                            // console.log(urlDescarga)
                            this.saveChange({fixText: urlDescarga, idc: 8, oldText: payload.old ? payload.old : payload.oldText ? payload.oldText : '', idError: payload.idError, fixTextAlt: '', img: urlDescarga, idrequest: payload.idrequest})

                            // setTimeout(() => {
                            //     this.uploadImgs.push(payload.idError)
                            //     this.processingUploadImg = this.processingUploadImg.filter(ele => ele !== payload.idError)
                            //     // this.filesMI = []
                            //     // this.filePreviewsMI = []
                            //     this.filePreviewsMI = this.filePreviewsMI.filter(ele => ele.id !== payload.idError)
                            // }, 3500)
                        }
                    } catch (error) {
                        console.error('Error al subir el archivo:', error);
                    }
                })
                .catch(e => {
                    console.log(e.message)
                })
            // } else {
            //     this.processingSaveData = false
            // }
        },
        saveDL() {

        },
        saveChange(payload) {
            // console.log('flag');
            // console.log(payload)
            this.processingSaveData = true
            var user = auth.currentUser
            this.oldText = ''
            this.oldText = payload.oldText
            this.idProcessSave = payload.fixText
            // this.manualAlt = ''

            var urlWindow = window.location.href
            var urlWindowTransform = new URL(urlWindow)
            if(urlWindowTransform.pathname === '/share') {
                localStorage.setItem('takeScreenShare', 'true')
                this.processingSaveData = false
            } else if(user) {
                // console.log(this.sitesProPages.includes(this.IASiteSelected[0].cUrl),this.logicOwner)
                if(this.logicOwner === 'me') {
                    // console.log('asdf1')
                    if(this.sitesProPages.includes(this.IASiteSelected[0].cUrl)) {
                        if(payload.idc === 1 || payload.idc === 2) {
                            this.processSavingDescTitle.push(payload.fixText)
                        } else {
                            this.processSavingImgs.push(payload.fixText)
                        }

                        // save changes
                        db.collection('Users').doc(user.uid).get()
                        .then(userDoc => {
                            var authUser = {
                                nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
                                email: userDoc.data().email,
                                uid: userDoc.data().uid,
                                foto: userDoc.data().foto
                            }

                            if(payload.idc === 8 && this.orderError === 'mIdL') {
                                this.saveDL(payload,authUser)
                            }

                            if(payload.idc === 8) {
                                this.savingIDMI.push(payload.idError)
                            }

                            var catRow = payload.idc
                            // console.log(this.optionsChatGPT<span style="font-size: 14px; font-weight: 500;">Errors</span>;
                            var errorsGPTFilter = this.optionsChatGPTErrors.filter(ele => ele.idc === catRow)
                            var existRow = payload.idc === 8 ? true : payload.idc === 3 ? true : errorsGPTFilter[0].exist
                            var idERow = payload.idc === 8 ? payload.idError : payload.idc === 3 ? payload.idError : errorsGPTFilter[0].id

                            payload.idc === 1 ? this.savingTitles = [payload.fixText] : null
                            payload.idc === 2 ? this.savingDescriptions = [payload.fixText] : null

                            payload.idc === 1 ? this.siteTitleSaved = payload.fixText : null
                            payload.idc === 2 ? this.siteDescriptionSaved = payload.fixText : null

                            payload.idc === 1 ? this.siteTitle = payload.fixText : null
                            payload.idc === 2 ? this.siteDescription = payload.fixText : null

                            payload.idc === 8 ? this.savingMI = [payload.fixText] : null
                            payload.idc === 3 ? this.savingMA = [payload.fixText, payload.idError] : null

                            // payload.idc === 1 ? localStorage.setItem('newTitle', payload.fixText) : null
                            // payload.idc === 2 ? localStorage.setItem('newDesc', payload.fixText) : null

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).get()
                            .then(siteProject => {
                            db.collection('Sites').doc(this.SiteID).get()
                            .then(siteDoc => {
                                var listCounterPage = siteDoc.data().errorsPages && siteDoc.data().errorsPages.length ? siteDoc.data().errorsPages : []
                                var filterCounterPage = listCounterPage.filter(eleErr => eleErr.url === this.IASiteSelected[0].cUrl)
                                var docsObjErrors = siteDoc.data().errorsTypePage && siteDoc.data().errorsTypePage.length >= 1 ? siteDoc.data().errorsTypePage : []
                                var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.IASiteSelected[0].cUrl)
                                var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.IASiteSelected[0].cUrl)
                                if(existRow === true) {
                                    filterObjErrorsFilter.forEach(elePage => {
                                    if(elePage.errors && elePage.errors.length) {
                                        elePage.errors.forEach(eleCounter => {
                                        if(payload.idc === 1) {
                                            if(eleCounter.type === 'title') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 2) {
                                            if(eleCounter.type === 'desc') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 4) {
                                            if(eleCounter.type === 'h1') {
                                            this.counterListError >= 2 ? eleCounter.counter -= 1 : null
                                            }
                                        } else if(payload.idc === 5) {
                                            if(eleCounter.type === 'h2') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 8) {
                                            if(eleCounter.type === 'imgs') {
                                            // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                                            eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                                            }
                                        } else if(payload.idc === 6) {
                                            if(eleCounter.type === 'lWord') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 13) {
                                            if(eleCounter.type === 'strings') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 3) {
                                            if(eleCounter.type === 'mAlt') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 9) {
                                            if(eleCounter.type === 'dLink') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 14) {
                                            if(eleCounter.type === 'imgs') {
                                            // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                                            eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                                            }
                                        } else if(payload.idc === 11) {
                                            if(eleCounter.type === 'urlCan') {
                                            eleCounter.counter -= 1
                                            }
                                        } else if(payload.idc === 10) {
                                            // if(eleCounter.type === 'urlCan') {
                                            //   eleCounter.counter -= 1
                                            // }
                                        } else if(payload.idc === 15) {
                                            if(eleCounter.type === 'strings') {
                                            eleCounter.counter -= 1
                                            }
                                        }
                                        })
                                    }
                                    })

                                    var counterFixErr = 0
                                    filterObjErrorsFilter.forEach(eleSiteTypeError => {
                                    if(counterFixErr === 0) {
                                        counterFixErr += 1
                                        filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                                    }
                                    })

                                    db.collection('Sites').doc(siteDoc.id).update({
                                    errorsTypePage: filterObjNoSiteErrorsFilter
                                    })
                                }

                                if(payload.idc === 1) {
                                    var arrayPages = siteDoc.data().pages && siteDoc.data().pages.length >= 1 ? siteDoc.data().pages : []
                                    var filterPageTitle = arrayPages.filter(ele => ele.url === this.IASiteSelected[0].cUrl && ele.status !== null)
                                    var filterNoPageTitle = arrayPages.filter(ele => ele.url !== this.IASiteSelected[0].cUrl)
                                    if(filterPageTitle.length >= 1) {
                                    var counterPageT = 0
                                    filterPageTitle.forEach(ele => {
                                        if(counterPageT === 0) {
                                        counterPageT += 1
                                        ele.title = payload.fixText
                                        filterNoPageTitle.push(ele)
                                        }
                                    })

                                    db.collection('Sites').doc(siteDoc.id).update({
                                        pages: filterNoPageTitle
                                    })
                                    console.log('updated title')
                                    }
                                }

                                dbErrors.collection('fixes').where('idpage', '==', this.IASiteSelected[0].cUrl).limit(1).get()
                                .then(docPageFixCollection => {
                                dbErrors.collection('fixes_cache').where('SiteID', '==', siteDoc.id).limit(1).get()
                                .then(docPageFixCacheCollection => {
                                    var idDocPageFixCacheColl = ''
                                    var changesFixesCache = []
                                    docPageFixCacheCollection.forEach(docPageFixCacheColl => {
                                        idDocPageFixCacheColl = docPageFixCacheColl.id
                                        changesFixesCache = docPageFixCacheColl.data().changes
                                    })

                                    // exist already fix document
                                    if(docPageFixCollection.size >= 1) {
                                    var idDocPageFixColl = ''
                                    var counterErrorFixes = 0
                                    var arrayFixesDomain = []
                                    var originalTextFix = ''
                                    docPageFixCollection.forEach(docPageFixColl => {
                                        idDocPageFixColl = docPageFixColl.id
                                        if(docPageFixColl.data().changes && docPageFixColl.data().changes.length >= 1) {
                                        docPageFixColl.data().changes.forEach(ele => {
                                            arrayFixesDomain.push(ele)
                                            if(ele.idfix === 1 && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl && ele.id === payload.idError) {
                                            payload.idc === 3 ? originalTextFix = ele.urlImg : originalTextFix = ele.old
                                            }
                                        })
                                        }
                                    })
                                    // !originalTextFix ? originalTextFix = this.oldText : null
                                    counterErrorFixes = (arrayFixesDomain.filter(ele => ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl && ele.id === payload.idError).length) + 1
                                    if(arrayFixesDomain.filter(ele => ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl && ele.id === payload.idError).length === 0) {
                                        payload.idc === 3 ? originalTextFix = payload.img : originalTextFix = this.oldText
                                    }
                                    !originalTextFix && payload.idc === 3 ? originalTextFix = payload.img : !originalTextFix ? originalTextFix = this.oldText : null

                                    dbErrors.collection('Errors').where('idpage', '==', this.IASiteSelected[0].cUrl).limit(1).get()
                                    .then(docPageError => {
                                        var idDocError = ''
                                        var changesDocError = []
                                        var listErrors = []
                                        var changesPage = []
                                        docPageError.forEach(docError => {
                                            idDocError = docError.id
                                            changesDocError = docError.data().changes ? docError.data().changes : []
                                            listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                            changesPage = docError.data().changes ? docError.data().changes : []
                                        })

                                        var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                        var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.IASiteSelected[0].cUrl)
                                        if(!changesPage.length) {
                                        // update asignated pro and sizes
                                        db.collection('Sites').doc(siteDoc.id).update({
                                            asignatedProPages: true,
                                            pageSizes: filterSizePageExclude
                                        })

                                        // update error status and cont/est
                                        var filterErr = []
                                        if(payload.idc === 8) {
                                            filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                            // console.log(filterErr.length);

                                            if(filterErr.length) {
                                            filterErr.forEach(ele => {
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                var originalIE = ele.iE
                                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                    ele.iE = false
                                                } else {
                                                    this.counterListError >= 2 ? null : ele.iE = false
                                                }

                                                ele.fixedImg = true
                                                if(!ele.initImg && ele.cont) {
                                                    ele.initImg = ele.cont
                                                }

                                                if(ele.idc === 3) {
                                                    ele.est = payload.fixTextAlt
                                                    ele.cont = payload.fixText
                                                    ele.sta = 200
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                    if(originalIE) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }
                                                    }
                                                } else {
                                                    ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                    ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                }
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                .catch(e => console.log('Error add err: ', e.message))
                                                })
                                                .catch(e => console.log('Error delete err: ', e.message))
                                            })
                                            }
                                        } else {
                                            filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                            if(filterErr.length) {
                                            filterErr.forEach(ele => {
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                    ele.iE = false
                                                } else {
                                                    this.counterListError >= 2 ? null : ele.iE = false
                                                }
                                                payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                .catch(e => console.log('Error add err: ', e.message))
                                                })
                                                .catch(e => console.log('Error delete err: ', e.message))
                                            })
                                            }
                                        }

                                        // update error counter category
                                        if(payload.idc === 1) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 2) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 4) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                            if(this.counterListError <= 1) {
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 5) {
                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                            //   })
                                            // }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 8) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                            })

                                                // console.log(siteProject.data().totalErrors)
                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 6) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 13) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 3) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 9) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 14) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 11) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 10) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 15) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                            })
                                                
                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                            //   })
                                            // }
                                        }
                                        
                                        // update error counters by page
                                        if(filterCounterPage.length) {
                                            // console.log(filterCounterPage.length)
                                            filterErr.forEach(ele => {
                                            if(ele.counter) {
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                ele.counter = ele.counter - 1
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                    errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                })
                                            }
                                            })
                                        }

                                        // console.log(changesDocError.length, idDocError)
                                        if(changesPage.length) {
                                            // update asignated sizes
                                            db.collection('Sites').doc(siteDoc.id).update({
                                            pageSizes: filterSizePageExclude
                                            })

                                            // update Errors page
                                            if(idDocError) {
                                            if(changesDocError.length) {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            } else {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: [{
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }],
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            }
                                            }
                                        } else {
                                            // update asignated sizes
                                            db.collection('Sites').doc(siteDoc.id).update({
                                            pageSizes: filterSizePageExclude
                                            })

                                            // update Errors page
                                            if(idDocError) {
                                            if(changesDocError.length) {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            } else {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: [{
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }],
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            }
                                            }
                                        }

                                        setTimeout(() => {
                                            this.$emit('update:changeerror', true)
                                            var arrayChan = []
                                            // this.arraychanges.forEach(ele => {
                                            //     arrayChan.push(ele)
                                            // })
                                            arrayChan.push(payload.idError)
                                            // this.$emit('update:arraychanges', arrayChan)
                                            this.$toast({
                                                component: ToastificationContent,
                                                props: {
                                                    title: `Change applied`,
                                                    icon: 'BellIcon',
                                                    variant: 'success',
                                                },
                                            })
                                            // this.$refs.sidebar_fix_error.hide()
                                            payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                            payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                            payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                            payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                            localStorage.setItem('updateErrChange', true)
                                            // this.updateErrorsStatus()
                                            this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                            // setTimeout(() => {
                                            //     this.processingSaveData = false
                                            //     if(payload.idc === 1 || payload.idc === 2) {
                                            //         this.processSavingDescTitle = this.processSavingDescTitle.filter(ele => ele !== payload.fixText)
                                            //     } else {
                                            //         this.processSavingImgs = this.processSavingImgs.filter(ele => ele !== payload.fixText)
                                            //     }
                                            // }, 1300)
                                            payload.fixText = ''
                                            payload.fixTextAlt = ''
                                            this.oldText = ''
                                        }, 1700)
                                        } else {
                                        // update asignated pro
                                        db.collection('Sites').doc(siteDoc.id).update({
                                            asignatedProPages: true,
                                            pageSizes: filterSizePageExclude
                                        })

                                        // update error status and cont/est
                                        var filterErr = []
                                        if(payload.idc === 8) {
                                            filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                            // console.log(filterErr.length);

                                            if(filterErr.length) {
                                            filterErr.forEach(ele => {
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                var originalIE = ele.iE
                                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                    ele.iE = false
                                                } else {
                                                    this.counterListError >= 2 ? null : ele.iE = false
                                                }

                                                ele.fixedImg = true
                                                if(!ele.initImg && ele.cont) {
                                                    ele.initImg = ele.cont
                                                }

                                                if(ele.idc === 3) {
                                                    ele.est = payload.fixTextAlt
                                                    ele.cont = payload.fixText
                                                    ele.sta = 200
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                    if(originalIE) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }
                                                    }
                                                } else {
                                                    ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                    ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                }
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                .catch(e => console.log('Error add err: ', e.message))
                                                })
                                                .catch(e => console.log('Error delete err: ', e.message))
                                            })
                                            }
                                        } else {
                                            filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                            if(filterErr.length) {
                                            filterErr.forEach(ele => {
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                    ele.iE = false
                                                } else {
                                                    this.counterListError >= 2 ? null : ele.iE = false
                                                }
                                                payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                // console.log(ele)
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                .catch(e => console.log('Error add err: ', e.message))
                                                })
                                                .catch(e => console.log('Error delete err: ', e.message))
                                            })
                                            }
                                        }

                                        // update error counter category
                                        if(payload.idc === 1) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 2) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 4) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                            if(this.counterListError <= 1) {
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 5) {
                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                            //   })
                                            // }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 8) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 6) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 13) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 3) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 9) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 14) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 11) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 10) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                            //   })
                                            // }
                                        } else if(payload.idc === 15) {
                                            if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                            db.collection('Sites').doc(siteDoc.id).update({
                                                'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                            })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                            }

                                            // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                            //   db.collection('Sites').doc(siteDoc.id).update({
                                            //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                            //   })
                                            // }
                                        }
                                        
                                        // update error counters by page
                                        if(filterCounterPage.length) {
                                            filterErr.forEach(ele => {
                                            if(ele.counter) {
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                })
                                                .then(() => {
                                                ele.counter = ele.counter - 1
                                                db.collection('Sites').doc(siteDoc.id).update({
                                                    errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                })
                                                })
                                            }
                                            })
                                        }

                                        if(changesPage.length) {
                                            // update asignated sizes
                                            db.collection('Sites').doc(siteDoc.id).update({
                                            pageSizes: filterSizePageExclude
                                            })

                                            // update Errors page
                                            if(idDocError) {
                                            if(changesDocError.length) {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            } else {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: [{
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }],
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            }
                                            }
                                        } else {
                                            // update asignated sizes
                                            db.collection('Sites').doc(siteDoc.id).update({
                                            pageSizes: filterSizePageExclude
                                            })

                                            // update Errors page
                                            if(idDocError) {
                                            if(changesDocError.length) {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            } else {
                                                dbErrors.collection('Errors').doc(idDocError).update({
                                                changes: [{
                                                    id: payload.idError,
                                                    idError: payload.idc,
                                                    typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: payload.fixText,
                                                    url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                    date: Date.now(),
                                                    user: authUser,
                                                    idfix: counterErrorFixes
                                                }],
                                                has_fix: true
                                                })
                                                .catch(e => console.log(e.message))
                                            }
                                            }
                                        }

                                        setTimeout(() => {
                                            this.$emit('update:changeerror', true)
                                            var arrayChan = []
                                            // this.arraychanges.forEach(ele => {
                                            // arrayChan.push(ele)
                                            // })
                                            arrayChan.push(payload.idError)
                                            // this.$emit('update:arraychanges', arrayChan)
                                            this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                                title: `Change applied`,
                                                icon: 'BellIcon',
                                                variant: 'success',
                                            },
                                            })
                                            // this.$refs.sidebar_fix_error.hide()
                                            payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                            payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                            payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                            payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                            localStorage.setItem('updateErrChange', true)
                                            // this.updateErrorsStatus()
                                            this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                            // setTimeout(() => {
                                            //     this.processingSaveData = false
                                            //     if(payload.idc === 1 || payload.idc === 2) {
                                            //         this.processSavingDescTitle = this.processSavingDescTitle.filter(ele => ele !== payload.fixText)
                                            //     } else {
                                            //         this.processSavingImgs = this.processSavingImgs.filter(ele => ele !== payload.fixText)
                                            //     }
                                            // }, 1300)
                                            payload.fixText = ''
                                            payload.fixTextAlt = ''
                                            this.oldText = ''
                                        }, 1700)
                                        }

                                        if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                        // console.log('1')
                                        if(payload.idc === 3) {
                                            dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                user: authUser.uid,
                                                idfix: counterErrorFixes
                                            }),
                                            has_fix: true,
                                            updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))

                                            var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.IASiteSelected[0].cUrl)
                                            if(filterchangesFixesCache8.length === 0) {
                                            } else {
                                            var filterNochangesFixesCache = []
                                            var idChangeCache = ''
                                            changesFixesCache.forEach(ele => {
                                                if(ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.IASiteSelected[0].cUrl) {
                                                idChangeCache = ele.id
                                                } else {
                                                filterNochangesFixesCache.push(ele)
                                                }
                                            })
                                            filterNochangesFixesCache.push({
                                                id: idChangeCache,
                                                idError: 8,
                                                typeError: 'Images',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                            })

                                            dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: filterNochangesFixesCache[0].id,
                                                idError: 8,
                                                typeError: 'Images',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                user: authUser.uid,
                                                idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))

                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: filterNochangesFixesCache,
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            }

                                            var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                            if(filterchangesFixesCache.length === 0) {
                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))
                                            } else {
                                            // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                            var filterNochangesFixesCache = []
                                            changesFixesCache.forEach(ele => {
                                                // console.log(ele)
                                                if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                // filterNochangesFixesCache.push(ele)
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                } else {
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                filterNochangesFixesCache.push(ele)
                                                }
                                            })
                                            // console.log(filterNochangesFixesCache, changesFixesCache)
                                            filterNochangesFixesCache.push({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                            })

                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: filterNochangesFixesCache,
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            }
                                        } else {
                                            dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                user: authUser.uid,
                                                idfix: counterErrorFixes
                                            }),
                                            has_fix: true,
                                            updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))

                                            var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.IASiteSelected[0].cUrl)
                                            if(filterchangesFixesCache3.length === 0) {
                                            } else {
                                            var filterNochangesFixesCache = []
                                            var idChangeCache = ''
                                            changesFixesCache.forEach(ele => {
                                                if(ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.IASiteSelected[0].cUrl) {
                                                idChangeCache = ele.id
                                                } else {
                                                filterNochangesFixesCache.push(ele)
                                                }
                                            })
                                            filterNochangesFixesCache.push({
                                                id: idChangeCache,
                                                idError: 3,
                                                typeError: 'Missing alts',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                            })

                                            dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: filterNochangesFixesCache[0].id,
                                                idError: 3,
                                                typeError: 'Missing alts',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                user: authUser.uid,
                                                idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))

                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: filterNochangesFixesCache,
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            }

                                            var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                            if(filterchangesFixesCache.length === 0) {
                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))
                                            } else {
                                            // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                            var filterNochangesFixesCache = []
                                            changesFixesCache.forEach(ele => {
                                                // console.log(ele)
                                                if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                // filterNochangesFixesCache.push(ele)
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                } else {
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                filterNochangesFixesCache.push(ele)
                                                }
                                            })
                                            // console.log(filterNochangesFixesCache, changesFixesCache)
                                            filterNochangesFixesCache.push({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: payload.idc === 3 ? this.oldText : originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                alt: payload.fixTextAlt,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                urlImg: originalTextFix ? originalTextFix : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                            })

                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: filterNochangesFixesCache,
                                                has_fix: true,
                                                updated: Date.now()
                                            })
                                            }
                                        }
                                        } else {
                                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                            id: payload.idError,
                                            idError: payload.idc,
                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                            old: originalTextFix ? originalTextFix : '',
                                            fix: payload.fixText,
                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                            date: Date.now(),
                                            user: authUser.uid,
                                            idfix: counterErrorFixes
                                            }),
                                            has_fix: true,
                                            updated: Date.now()
                                        })
                                        .catch(e => console.log(e.message))

                                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                        // console.log(filterchangesFixesCache)
                                        if(filterchangesFixesCache.length === 0) {
                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: payload.idError,
                                                idError: payload.idc,
                                                typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                old: originalTextFix ? originalTextFix : '',
                                                fix: payload.fixText,
                                                url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                            }),
                                            has_fix: true,
                                            updated: Date.now()
                                            })
                                            .catch(e => console.log(e.message))
                                        } else {
                                            // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                            var filterNochangesFixesCache = []
                                            changesFixesCache.forEach(ele => {
                                            // console.log(ele)
                                            if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                // filterNochangesFixesCache.push(ele)
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                            } else {
                                                // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                filterNochangesFixesCache.push(ele)
                                            }
                                            })
                                            // console.log(filterNochangesFixesCache, changesFixesCache)
                                            filterNochangesFixesCache.push({
                                            id: payload.idError,
                                            idError: payload.idc,
                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                            old: originalTextFix ? originalTextFix : '',
                                            fix: payload.fixText,
                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                            date: Date.now(),
                                            // user: authUser,
                                            idfix: counterErrorFixes
                                            })

                                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                            changes: filterNochangesFixesCache,
                                            has_fix: true,
                                            updated: Date.now()
                                            })
                                        }
                                        }
                                    })
                                    } else {
                                        if(!idDocPageFixCacheColl) {
                                            // doesnt exist fix document
                                            dbErrors.collection('fixes_cache').add({
                                            SiteID: siteDoc.id,
                                            // idpage: this.IASiteSelected[0].cUrl,
                                            owner: authUser.uid,
                                            ownerEmail: authUser.email,
                                            changes: [],
                                            updated: Date.now()
                                            }).then((docRefFixCacheColl) => {
                                            idDocPageFixCacheColl = docRefFixCacheColl.id
                                            
                                            dbErrors.collection('fixes').add({
                                                SiteID: siteDoc.id,
                                                idpage: this.IASiteSelected[0].cUrl,
                                                changes: [],
                                                updated: Date.now()
                                            }).then((docRefFixColl) => {
                                                var idDocPageFixColl = docRefFixColl.id
                                                // var idDocPageFixCacheColl = docRefFixCacheColl.id

                                                dbErrors.collection('Errors').where('idpage', '==', this.IASiteSelected[0].cUrl).limit(1).get()
                                                .then(docPageError => {
                                                var idDocError = ''
                                                var changesDocError = []
                                                var listErrors = []
                                                var changesPage = []
                                                docPageError.forEach(docError => {
                                                    idDocError = docError.id
                                                    changesDocError = docError.data().changes ? docError.data().changes : []
                                                    listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                                    changesPage = docError.data().changes ? docError.data().changes : []
                                                })

                                                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.IASiteSelected[0].cUrl)
                                                if(!changesPage.length) {
                                                    // update asignated pro and sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(payload.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                                    // console.log(filterErr.length);

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            ele.fixedImg = true
                                                            if(!ele.initImg && ele.cont) {
                                                                ele.initImg = ele.cont
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = payload.fixTextAlt
                                                            ele.cont = payload.fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(siteDoc.id).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                        totalErrors: siteProject.data().totalErrors - 1
                                                                    })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(payload.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(this.counterListError <= 1) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                    this.$emit('update:changeerror', true)
                                                    var arrayChan = []
                                                    // this.arraychanges.forEach(ele => {
                                                    //     arrayChan.push(ele)
                                                    // })
                                                    arrayChan.push(payload.idError)
                                                    // this.$emit('update:arraychanges', arrayChan)
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        props: {
                                                        title: `Change applied`,
                                                        icon: 'BellIcon',
                                                        variant: 'success',
                                                        },
                                                    })
                                                    // this.$refs.sidebar_fix_error.hide()
                                                    payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                                    payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                                    payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                                    payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                                    localStorage.setItem('updateErrChange', true)
                                                    // this.updateErrorsStatus()
                                                    this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                                    // setTimeout(() => {
                                                    //     this.processingSaveData = false
                                                    //     if(payload.idc === 1 || payload.idc === 2) {
                                                    //         this.processSavingDescTitle = this.processSavingDescTitle.filter(ele => ele !== payload.fixText)
                                                    //     } else {
                                                    //         this.processSavingImgs = this.processSavingImgs.filter(ele => ele !== payload.fixText)
                                                    //     }
                                                    // }, 1300)
                                                    payload.fixText = ''
                                                    payload.fixTextAlt = ''
                                                    this.oldText = ''
                                                    }, 1700)
                                                } else {
                                                    // update asignated pro
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(payload.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                                    // console.log(filterErr.length);

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            ele.fixedImg = true
                                                            if(!ele.initImg && ele.cont) {
                                                                ele.initImg = ele.cont
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = payload.fixTextAlt
                                                            ele.cont = payload.fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(siteDoc.id).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                        totalErrors: siteProject.data().totalErrors - 1
                                                                    })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(payload.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(this.counterListError <= 1) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                    this.$emit('update:changeerror', true)
                                                    var arrayChan = []
                                                    // this.arraychanges.forEach(ele => {
                                                    //     arrayChan.push(ele)
                                                    // })
                                                    arrayChan.push(payload.idError)
                                                    // this.$emit('update:arraychanges', arrayChan)
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        props: {
                                                        title: `Change applied`,
                                                        icon: 'BellIcon',
                                                        variant: 'success',
                                                        },
                                                    })
                                                    // this.$refs.sidebar_fix_error.hide()
                                                    payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                                    payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                                    payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                                    payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                                    localStorage.setItem('updateErrChange', true)
                                                    // this.updateErrorsStatus()
                                                    this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                                    // setTimeout(() => {
                                                    //     this.processingSaveData = false
                                                    //     if(payload.idc === 1 || payload.idc === 2) {
                                                    //         this.processSavingDescTitle = this.processSavingDescTitle.filter(ele => ele !== payload.fixText)
                                                    //     } else {
                                                    //         this.processSavingImgs = this.processSavingImgs.filter(ele => ele !== payload.fixText)
                                                    //     }
                                                    // }, 1300)
                                                    payload.fixText = ''
                                                    payload.fixTextAlt = ''
                                                    this.oldText = ''
                                                    }, 1700)
                                                }

                                                if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                    // console.log('2')
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))

                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))
                                                } else {
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))

                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))
                                                }
                                                })
                                            })
                                            })
                                        } else {
                                            // doesnt exist fix document
                                            dbErrors.collection('fixes').add({
                                                SiteID: siteDoc.id,
                                                idpage: this.IASiteSelected[0].cUrl,
                                                changes: [],
                                                updated: Date.now()
                                            }).then((docRefFixColl) => {
                                                var idDocPageFixColl = docRefFixColl.id
                                                // var idDocPageFixCacheColl = docRefFixCacheColl.id

                                                dbErrors.collection('Errors').where('idpage', '==', this.IASiteSelected[0].cUrl).limit(1).get()
                                                .then(docPageError => {
                                                var idDocError = ''
                                                var changesDocError = []
                                                var listErrors = []
                                                var changesPage = []
                                                docPageError.forEach(docError => {
                                                    idDocError = docError.id
                                                    changesDocError = docError.data().changes ? docError.data().changes : []
                                                    listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                                    changesPage = docError.data().changes ? docError.data().changes : []
                                                })

                                                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.IASiteSelected[0].cUrl)
                                                if(!changesPage.length) {
                                                    // update asignated pro and sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(payload.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                                    // console.log(filterErr.length);

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            ele.fixedImg = true
                                                            if(!ele.initImg && ele.cont) {
                                                                ele.initImg = ele.cont
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = payload.fixTextAlt
                                                            ele.cont = payload.fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(siteDoc.id).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                        totalErrors: siteProject.data().totalErrors - 1
                                                                    })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(payload.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(this.counterListError <= 1) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                    this.$emit('update:changeerror', true)
                                                    var arrayChan = []
                                                    // this.arraychanges.forEach(ele => {
                                                    //     arrayChan.push(ele)
                                                    // })
                                                    arrayChan.push(payload.idError)
                                                    // this.$emit('update:arraychanges', arrayChan)
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        props: {
                                                        title: `Change applied`,
                                                        icon: 'BellIcon',
                                                        variant: 'success',
                                                        },
                                                    })
                                                    // this.$refs.sidebar_fix_error.hide()
                                                    payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                                    payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                                    payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                                    payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                                    localStorage.setItem('updateErrChange', true)
                                                    // this.updateErrorsStatus()
                                                    this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                                    // setTimeout(() => {
                                                    //     this.processingSaveData = false
                                                    //     if(payload.idc === 1 || payload.idc === 2) {
                                                    //         this.processSavingDescTitle = this.processSavingDescTitle.filter(ele => ele !== payload.fixText)
                                                    //     } else {
                                                    //         this.processSavingImgs = this.processSavingImgs.filter(ele => ele !== payload.fixText)
                                                    //     }
                                                    // }, 1300)
                                                    payload.fixText = ''
                                                    payload.fixTextAlt = ''
                                                    this.oldText = ''
                                                    }, 1700)
                                                } else {
                                                    // update asignated pro
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(payload.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.IASiteSelected[0].cUrl))
                                                    // console.log(filterErr.length);

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            ele.fixedImg = true
                                                            if(!ele.initImg && ele.cont) {
                                                                ele.initImg = ele.cont
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = payload.fixTextAlt
                                                            ele.cont = payload.fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(siteDoc.id).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                        totalErrors: siteProject.data().totalErrors - 1
                                                                    })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            this.counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                                            payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                                            // console.log(ele)
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(payload.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(this.counterListError <= 1) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                                totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(payload.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(siteDoc.id).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(siteDoc.id).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(siteDoc.id).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                    this.$emit('update:changeerror', true)
                                                    var arrayChan = []
                                                    // this.arraychanges.forEach(ele => {
                                                    //     arrayChan.push(ele)
                                                    // })
                                                    arrayChan.push(payload.idError)
                                                    // this.$emit('update:arraychanges', arrayChan)
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        props: {
                                                        title: `Change applied`,
                                                        icon: 'BellIcon',
                                                        variant: 'success',
                                                        },
                                                    })
                                                    // this.$refs.sidebar_fix_error.hide()
                                                    payload.idc === 1 ? this.savedTitle = [payload.fixText] : null
                                                    payload.idc === 2 ? this.savedDescription = [payload.fixText] : null
                                                    payload.idc === 8 ? this.savedMI = [payload.fixText] : null
                                                    payload.idc === 3 ? this.savedMA = [payload.fixText, payload.idError] : null
                                                    localStorage.setItem('updateErrChange', true)
                                                    // this.updateErrorsStatus()
                                                    this.sendJSONFix(idDocPageFixCacheColl, payload.idc, (payload.idrequest ? payload.idrequest : ''))
                                                    payload.fixText = ''
                                                    payload.fixTextAlt = ''
                                                    this.oldText = ''
                                                    }, 1700)
                                                }

                                                if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                                    // console.log('3')
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))

                                                    if(changesFixesCache.length >= 1) {
                                                    if(payload.idc === 3) {
                                                        var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === this.oldText && ele.idError === 8 && ele.url === this.IASiteSelected[0].cUrl)
                                                        if(filterchangesFixesCache8.length === 0) {
                                                        } else {
                                                        var filterNochangesFixesCache = []
                                                        var idChangeCache = ''
                                                        changesFixesCache.forEach(ele => {
                                                            if(ele.urlImg === this.oldText && ele.idError === 8 && ele.url === this.IASiteSelected[0].cUrl) {
                                                            idChangeCache = ele.id
                                                            } else {
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        filterNochangesFixesCache.push({
                                                            id: idChangeCache,
                                                            idError: 8,
                                                            typeError: 'Images',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: counterErrorFixes
                                                        })

                                                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: filterNochangesFixesCache[0].id,
                                                            idError: 8,
                                                            typeError: 'Images',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            user: authUser.uid,
                                                            idfix: counterErrorFixes
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        .catch(e => console.log(e.message))

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }

                                                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                                        if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                            // console.log(ele)
                                                            if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                            } else {
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }
                                                    } else {
                                                        var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === this.oldText && ele.idError === 3 && ele.url === this.IASiteSelected[0].cUrl)
                                                        if(filterchangesFixesCache3.length === 0) {
                                                        } else {
                                                        var filterNochangesFixesCache = []
                                                        var idChangeCache = ''
                                                        changesFixesCache.forEach(ele => {
                                                            if(ele.urlImg === this.oldText && ele.idError === 3 && ele.url === this.IASiteSelected[0].cUrl) {
                                                            idChangeCache = ele.id
                                                            } else {
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        filterNochangesFixesCache.push({
                                                            id: idChangeCache,
                                                            idError: 3,
                                                            typeError: 'Missing alts',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: counterErrorFixes
                                                        })

                                                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: filterNochangesFixesCache[0].id,
                                                            idError: 3,
                                                            typeError: 'Missing alts',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            user: authUser.uid,
                                                            idfix: counterErrorFixes
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        .catch(e => console.log(e.message))

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }

                                                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                                        if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        .catch(e => console.log(e.message))
                                                        } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                            // console.log(ele)
                                                            if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                            } else {
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            alt: payload.fixTextAlt,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        alt: payload.fixTextAlt,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        urlImg: payload.idc === 3 ? payload.img : this.oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        }],
                                                        has_fix: true,
                                                        updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))
                                                    }
                                                } else {
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))

                                                    if(changesFixesCache.length >= 1) {
                                                    var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl)
                                                    // console.log(filterchangesFixesCache)
                                                    if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: payload.idError,
                                                            idError: payload.idc,
                                                            typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                            old: this.oldText,
                                                            fix: payload.fixText,
                                                            url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        }),
                                                        has_fix: true,
                                                        updated: Date.now()
                                                        })
                                                        .catch(e => console.log(e.message))
                                                    } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== this.IASiteSelected[0].cUrl)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                        // console.log(ele)
                                                        if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === this.IASiteSelected[0].cUrl) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                        } else {
                                                            // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, this.IASiteSelected[0].cUrl)
                                                            filterNochangesFixesCache.push(ele)
                                                        }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: filterNochangesFixesCache,
                                                        has_fix: true,
                                                        updated: Date.now()
                                                        })
                                                    }
                                                    } else {
                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: [{
                                                        id: payload.idError,
                                                        idError: payload.idc,
                                                        typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                                        old: this.oldText,
                                                        fix: payload.fixText,
                                                        url: this.IASiteSelected[0].cUrl ? this.IASiteSelected[0].cUrl : '',
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        }],
                                                        has_fix: true,
                                                        updated: Date.now()
                                                    })
                                                    .catch(e => console.log(e.message))
                                                    }
                                                }
                                                })
                                            })
                                        }
                                    }
                                })
                                })
                            })
                            })
                        })
                    } else {
                        // console.log('asdf2')
                        // check valids propages
                        db.collection('Users').doc(user.uid).get()
                        .then(userDocCustomer => {
                            db.collection('Sites').doc(this.SiteID).get()
                            .then(docSite => {
                                db.collection('Users').doc(user.uid).collection('projects').doc(this.$route.params.project).get()
                                .then(eleUserProject => {
                                    var homepage = docSite.data().homepage
                                    var proPages = docSite.data().proPages ? docSite.data().proPages : []
                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.SiteID).get()
                                    .then(docProj => {
                                        if(docProj.exists) {
                                            var infoLicense = {}
                                            if(docProj.data().plan === 'free' || docProj.data().plan === 'Free' || !docProj.data().plan) {
                                                infoLicense = {
                                                    id: docProj.id,
                                                    img: docProj.data().thumbnail ? docProj.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                                    name: docProj.data().name ? docProj.data().name : docProj.data().WebsiteURL,
                                                    domainFixed: docProj.data().WebsiteURL,
                                                    project: eleUserProject.data().name,
                                                    statusSite: docProj.data().status,
                                                    domain: docProj.data().homepage,
                                                    projectid: eleUserProject.data().id,
                                                    color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                                                    own: 'me',
                                                    invitation: '',
                                                    imgSite: docProj.data().thumbnail ? docProj.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                                    plan: 'Free',
                                                    permission: 'owner',
                                                    amount: 'free',
                                                    nextDue: docProj.data().due ? moment(docProj.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-',
                                                    nextDueCancel: docProj.data().due ? moment(docProj.data().due*1000).add(1, 'month').format('ll') : '-',
                                                    // nextDueCancel: docProj.data().due ? moment(docProj.data().due*1000).format('MMM DD') : '-',
                                                    cancelSub: docProj.data().cancelSub ? docProj.data().cancelSub : false,
                                                    status: 'active',
                                                    payments: 'View Invoice',
                                                    freeTrial: docProj.data().freeTrial ? docProj.data().freeTrial : false,
                                                    customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                                                    customerIdSite: docProj.data().customerId ? docProj.data().customerId : '',
                                                    IDcustomerUser: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                                                    subscriptionId: docProj.data().subscriptionId ? docProj.data().subscriptionId : '',
                                                    planType: docProj.data().planType ? parseInt(docProj.data().planType, 10) : '',
                                                    planSite: docProj.data().plan ? docProj.data().plan : '',
                                                    paidRecurrency: docProj.data().paidRecurrency ? (docProj.data().paidRecurrency === 'day' || docProj.data().paidRecurrency === 'Day' ? 'Daily' : docProj.data().paidRecurrency === 'month' || docProj.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : '',
                                                }
                                            } else if(docProj.data().plan === 'basic' || docProj.data().plan === 'Basic' || docProj.data().plan === 'pro' || docProj.data().plan === 'Pro' || docProj.data().plan === 'custom' || docProj.data().plan === 'Custom' || docProj.data().plan === 'test' || docProj.data().plan === 'Test') {
                                                infoLicense = {
                                                    id: docProj.id,
                                                    img: docProj.data().thumbnail ? docProj.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                                    name: docProj.data().name ? docProj.data().name : docProj.data().WebsiteURL,
                                                    domainFixed: docProj.data().WebsiteURL,
                                                    project: eleUserProject.data().name,
                                                    statusSite: docProj.data().status,
                                                    domain: docProj.data().homepage,
                                                    projectid: eleUserProject.data().id,
                                                    color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                                                    own: 'me',
                                                    invitation: '',
                                                    imgSite: docProj.data().thumbnail ? docProj.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                                    plan: 'Pro',
                                                    permission: 'owner',
                                                    amount: '$20.00',
                                                    nextDue: docProj.data().due ? moment(docProj.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                                                    nextDueCancel: docProj.data().due ? moment(docProj.data().due*1000).add(1, 'month').format('ll') : '-',
                                                    cancelSub: docProj.data().cancelSub ? docProj.data().cancelSub : false,
                                                    status: 'active',
                                                    payments: 'View Invoice',
                                                    freeTrial: docProj.data().freeTrial ? docProj.data().freeTrial : false,
                                                    customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                                                    customerIdSite: docProj.data().customerId ? docProj.data().customerId : '',
                                                    IDcustomerUser: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                                                    subscriptionId: docProj.data().subscriptionId ? docProj.data().subscriptionId : '',
                                                    planType: docProj.data().planType ? parseInt(docProj.data().planType, 10) : '',
                                                    planSite: docProj.data().plan ? docProj.data().plan : '',
                                                    paidRecurrency: docProj.data().paidRecurrency ? (docProj.data().paidRecurrency === 'day' || docProj.data().paidRecurrency === 'Day' ? 'Daily' : docProj.data().paidRecurrency === 'month' || docProj.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : '',
                                                    pages: docProj.data().planType ? docProj.data().planType : ''
                                                }
                                            }
                                            var planType = docProj.data().planType ? parseInt(docProj.data().planType, 10) : 2

                                            var counterPros = proPages.length + 1
                                            if(counterPros > planType) {
                                                localStorage.setItem('jsonInfoLic', JSON.stringify(infoLicense))
                                                // this.$refs.sidebar_edit_image.hide()
                                                this.processingSaveData = false
                                            } else {
                                                // strings 13
                                                if(payload.idc === 1 || payload.idc === 2) {
                                                    var objErr = this.errorPageLog.filter(ele => ele.idc === payload.idc)
                                                    localStorage.setItem('viewTSProMake', JSON.stringify(objErr))
                                                    localStorage.setItem('fixLogMakePro', JSON.stringify({payload, optionsChatGPTErrors: this.optionsChatGPTErrors, siteId: this.SiteID, IASiteSelected: this.IASiteSelected, fixText: '', counterListError: this.counterListError}))
                                                    localStorage.setItem('fixCopilot', true)
                                                    // this.$refs.sidebar_edit_image.hide()
                                                    this.processingSaveData = false
                                                } else if(payload.idc === 3) {
                                                    var objErr = this.errorPageLog.filter(ele => ele.idc === 3 && ele.idError === payload.idError)
                                                    localStorage.setItem('viewTSProMake', JSON.stringify(objErr))
                                                    localStorage.setItem('fixLogMakePro', JSON.stringify({payload, optionsChatGPTErrors: this.optionsChatGPTErrors, siteId: this.SiteID, IASiteSelected: this.IASiteSelected, fixText: '', counterListError: this.counterListError}))
                                                    localStorage.setItem('fixCopilot', true)
                                                    // this.$refs.sidebar_edit_image.hide()
                                                    this.processingSaveData = false
                                                } else if(payload.idc === 8) {
                                                    var objErr = this.errorPageLog.filter(ele => ele.idc === 8 && ele.idError === payload.idError)
                                                    localStorage.setItem('viewTSProMake', JSON.stringify(objErr))
                                                    localStorage.setItem('fixLogMakePro', JSON.stringify({payload, optionsChatGPTErrors: this.optionsChatGPTErrors, siteId: this.SiteID, IASiteSelected: this.IASiteSelected, fixText: '', counterListError: this.counterListError}))
                                                    localStorage.setItem('fixCopilot', true)
                                                    // this.$refs.sidebar_edit_image.hide()
                                                    this.processingSaveData = false
                                                } else if(payload.idc === 9) {
                                                    var objErr = this.arrayDLs.filter(ele => ele.idError === payload.idError)
                                                    localStorage.setItem('viewTSProMake', JSON.stringify(objErr))
                                                    localStorage.setItem('fixLogMakePro', JSON.stringify({payload, optionsChatGPTErrors: this.optionsChatGPTErrors, siteId: this.SiteID, IASiteSelected: this.IASiteSelected, fixText: '', counterListError: this.counterListError}))
                                                    localStorage.setItem('fixCopilot', true)
                                                    // this.$refs.sidebar_edit_image.hide()
                                                    this.processingSaveData = false
                                                }
                                            }
                                        }
                                    })
                                })
                            })
                        })
                    }
                } else {
                    // console.log('asdf3')
                    localStorage.setItem('configRunLicense', true)
                    // this.$refs.sidebar_edit_image.hide()
                    this.processingSaveData = false
                }
            }
            // v-if="permission === 'me' && pros.includes(item.url)"
        },
        sendJSONFix(val, idc, idrequest) {
            console.log('flag fix json');
            this.$refs.sidebar_edit_image.hide()
            this.processingSaveData = false
            
            if(idc !== 7) {
                const user = auth.currentUser
                if(user) {
                    db.collection('Sites').doc(this.SiteID).get()
                    .then(site => {
                        if(site.data().owner && site.data().owner.uid) {
                        // check script exitense
                        if(user.uid === site.data().owner.uid) {
                            // idrequest
                            // if(idrequest) {
                            //     db.collection('Requests').doc(idrequest).delete()
                            //     .then(() => console.log('delete req ia'))
                            // }

                            var getFirstUser = user.uid.slice(0, 4);
                            var getFirstSite = site.id.slice(0, 4);
                            var keyID = getFirstUser + getFirstSite

                            var obj = {
                                "url": site.data().homepage,
                                "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                            }

                            var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(response => {
                                console.log('header: ',response.data)
                                if(!response.data.scriptPresent) {
                                    // this.isNotAlreadyOwner = false
                                    localStorage.setItem('configRunLicense', true)
                                } else {
                                    // this.isNotAlreadyOwner = true
                                }
                            })
                        } else {
                            // this.isNotAlreadyOwner = false
                            localStorage.setItem('configRunLicense', true)
                        }
                        } else {
                            // history owner lost
                            if(site.data().owners && site.data().owners.length) {
                                if(site.data().owners.includes(user.uid)) {
                                    // this.isNotAlreadyOwner = false
                                    localStorage.setItem('configRunLicense', true)
                                }
                            }
                        }
                    })

                    db.collection('Users').doc(user.uid).get()
                    .then(userDoc => {
                        setTimeout(() => {
                            db.collection('Sites').doc(this.SiteID).get()
                            .then(siteDoc => {
                            var proPages = siteDoc.data().proPages && siteDoc.data().proPages.length ? siteDoc.data().proPages : []

                            dbErrors.collection('fixes_cache').doc(val).get()
                            .then(docFixCache => {
                                var urlTransform = new URL(this.IASiteSelected[0].cUrl)
                                var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                const getFirstUser = userDoc.id.slice(0, 4);
                                const getFirstSite = this.SiteID.slice(0, 4);
                                // const getFirstProject = this.$route.params.project.slice(0, 3);
                                var keyID = getFirstUser + getFirstSite

                                var obj = {}
                                obj.author = userDoc.data().email
                                obj.css = ""
                                obj.domain = domainConverter
                                obj.key = keyID
                                obj.mode = 0

                                var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                                var pagesAlreadyProcessed = []
                                arraychanges.forEach(ele => {
                                    if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                                        var urlTransform = new URL(ele.url)
                                        // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                        pagesAlreadyProcessed.push(ele.url)
                                        var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                                        var encodedURL = btoa(pathURLConverter)
                                        obj[encodedURL] = {
                                            "title": "",
                                            "path": urlTransform.pathname,
                                            "url": ele.url,
                                            "description": "",
                                            "canonical": "",
                                            "siteName": "",
                                            "alt": [],
                                            "src": [],
                                            "dlinks": [],
                                            "gp": [],
                                            "tags": {},
                                            "tgstxt": {},
                                            "findAndReplace": []
                                        }
                                        var filterFixesOnlyEachPage = []
                                        // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                        var filterFixesOnlyEachPageWDL = arraychanges.filter(item => item.url === ele.url && item.idError === 9)
                                        var filterFixesOnlyEachPageNDL = arraychanges.filter(item => item.url === ele.url && item.idError !== 9)
                                        var condicionParaOrdenarList = (pageA, pageB) => {
                                            return pageB.idfix - pageA.idfix
                                        }
                                        filterFixesOnlyEachPageWDL.sort(condicionParaOrdenarList)
                                        // console.log(ele.url, this.oldText, filterFixesOnlyEachPageWDL)
                                        filterFixesOnlyEachPageWDL.forEach(eleErr => {
                                            if(filterFixesOnlyEachPage.filter(ele => ele.id === eleErr.id || ele.old === eleErr.old).length === 0) {
                                                filterFixesOnlyEachPage.push(eleErr)
                                            }
                                        })

                                        filterFixesOnlyEachPageNDL.forEach(eleErr => {
                                            filterFixesOnlyEachPage.push(eleErr)
                                        })

                                        // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                        filterFixesOnlyEachPage.forEach(item => {
                                            if(item.idError === 1) {
                                                // title
                                                obj[encodedURL].title = item.fix
                                                // obj[encodedURL].siteName = item.fix
                                            } else if(item.idError === 2) {
                                                // desc
                                                obj[encodedURL].description = item.fix
                                            } else if(item.idError === 4) {
                                                // h1
                                                if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                                                obj[encodedURL].tgstxt.h1.push({
                                                    txt: item.old,
                                                    ntx: item.fix
                                                })
                                                } else {
                                                obj[encodedURL].tgstxt.h1 = [{
                                                    txt: item.old,
                                                    ntx: item.fix
                                                }]
                                                }
                                            } else if(item.idError === 5) {
                                                // h2
                                                if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                                                obj[encodedURL].tgstxt.h2.push({
                                                    txt: item.old,
                                                    ntx: item.fix
                                                })
                                                } else {
                                                obj[encodedURL].tgstxt.h2 = [{
                                                    txt: item.old,
                                                    ntx: item.fix
                                                }]
                                                }
                                            } else if(item.idError === 8) {
                                                // imgs
                                                if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                                obj[encodedURL].src.push({
                                                    url: item.old,
                                                    src: item.fix
                                                })
                                                } else {
                                                obj[encodedURL].src = [{
                                                    url: item.old,
                                                    src: item.fix
                                                }]
                                                }

                                                if(item.alt) {
                                                if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                    obj[encodedURL].alt.push({
                                                    url: item.fix,
                                                    alt: item.alt
                                                    })
                                                } else {
                                                    obj[encodedURL].alt = [{
                                                    url: item.fix,
                                                    alt: item.alt
                                                    }]
                                                }
                                                }
                                            } else if(item.idError === 6) {
                                                // lWord
                                                // 08-03-2024 no available fix/edit (word count is value from crawl page)
                                            } else if(item.idError === 13) {
                                                // strings
                                                // if(item.findAndReplace) {
                                                if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                    obj[encodedURL].findAndReplace.push({
                                                    old: item.old,
                                                    new: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].findAndReplace = [{
                                                    old: item.old,
                                                    new: item.fix
                                                    }]
                                                }
                                                // }
                                            } else if(item.idError === 3) {
                                                // mAlt
                                                // if(item.alt) {
                                                if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                    obj[encodedURL].alt.push({
                                                    url: item.urlImg,
                                                    alt: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].alt = [{
                                                    url: item.urlImg,
                                                    alt: item.fix
                                                    }]
                                                }
                                                // }
                                            } else if(item.idError === 9) {
                                                // dLink
                                                // if(item.dlinks) {
                                                if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                                                    obj[encodedURL].dlinks.push({
                                                    url: item.old,
                                                    rmp: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].dlinks = [{
                                                    url: item.old,
                                                    rmp: item.fix
                                                    }]
                                                }
                                                // }
                                            } else if(item.idError === 14) {
                                                // missimgs
                                                if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                                obj[encodedURL].src.push({
                                                    url: item.old,
                                                    src: item.fix
                                                })
                                                } else {
                                                obj[encodedURL].src = [{
                                                    url: item.old,
                                                    src: item.fix
                                                }]
                                                }

                                                if(item.alt) {
                                                if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                    obj[encodedURL].alt.push({
                                                    url: item.fix,
                                                    alt: item.alt
                                                    })
                                                } else {
                                                    obj[encodedURL].alt = [{
                                                    url: item.fix,
                                                    alt: item.alt
                                                    }]
                                                }
                                                }
                                            } else if(item.idError === 11) {
                                                // urlCan
                                                obj[encodedURL].canonical = item.fix
                                            } else if(item.idError === 10) {
                                                // greenPadlockErrors
                                            } else if(item.idError === 15) {
                                                // Suspicious
                                                // if(item.findAndReplace) {
                                                if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                    obj[encodedURL].findAndReplace.push({
                                                    old: item.old,
                                                    new: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].findAndReplace = [{
                                                    old: item.old,
                                                    new: item.fix
                                                    }]
                                                }
                                                // }
                                            }
                                        })
                                    }
                                })

                                proPages.forEach(ele => {
                                    if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                                        var urlTransform = new URL(ele)

                                        pagesAlreadyProcessed.push(ele)
                                        var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                                        var encodedURL = btoa(pathURLConverter)
                                        obj[encodedURL] = {
                                            "title": "",
                                            "path": urlTransform.pathname,
                                            "url": ele,
                                            "description": "",
                                            "canonical": "",
                                            "siteName": "",
                                            "alt": [],
                                            "src": [],
                                            "dlinks": [],
                                            "gp": [],
                                            "tags": {},
                                            "tgstxt": {},
                                            "findAndReplace": [
                                                {
                                                "old": "seocloudtextfixer",
                                                "new": "SEOCloud text fixer"
                                                }
                                            ]
                                        }
                                    }
                                })

                                setTimeout(() => {
                                    // console.log(obj);
                                    var config = {
                                        method: 'POST',
                                        url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: JSON.stringify(obj)
                                    }

                                    this.axios(config)
                                    .then(response => {
                                        dbErrors.collection('fixes_cache').doc(val).update({
                                            api_file_request: obj
                                        })
                                        
                                        console.log('fixes file updated: '+response.data.status)
                                    })
                                    .catch(e => console.log(e.message))

                                    var objWP = {
                                        changes: [],
                                        redirects: []
                                    }

                                    Object.entries(obj).forEach(([key, value]) => {
                                        // console.log(`Clave: ${key}, Valor:`, value.a);
                                        if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                                            var objErrPageWP = {}
                                            objErrPageWP.url = value.url
                                            objErrPageWP.description = value.description
                                            objErrPageWP.canonical = value.canonical
                                            objErrPageWP.title = value.title
                                            objErrPageWP.search_and_replace = []
                                            objErrPageWP.images_src_replace = []
                                            objErrPageWP.images_alt_replace = []
                                            objErrPageWP.links_replace = []

                                            if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                                                value.tgstxt.h1.forEach(ele => {
                                                    objErrPageWP.search_and_replace.push({
                                                        'tag': 'h1',
                                                        'target_text': ele.txt,
                                                        'replace_text': ele.ntx
                                                    })
                                                })
                                            }

                                            if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                                                value.tgstxt.h2.forEach(ele => {
                                                    objErrPageWP.search_and_replace.push({
                                                        'tag': 'h2',
                                                        'target_text': ele.txt,
                                                        'replace_text': ele.ntx
                                                    })
                                                })
                                            }

                                            if(value.src && value.src.length) {
                                                value.src.forEach(ele => {
                                                    objErrPageWP.images_src_replace.push({
                                                        'target_src': ele.url,
                                                        'replace_src': ele.src
                                                    })
                                                })
                                            }

                                            if(value.alt && value.alt.length) {
                                                value.alt.forEach(ele => {
                                                    objErrPageWP.images_alt_replace.push({
                                                        'target_src': ele.url,
                                                        'replace_alt': ele.alt
                                                    })
                                                })
                                            }

                                            if(value.dlinks && value.dlinks.length) {
                                                value.dlinks.forEach(ele => {
                                                    objErrPageWP.links_replace.push({
                                                        'target_url': ele.url,
                                                        'replace_url': ele.rmp
                                                    })
                                                })
                                            }

                                            if(value.findAndReplace && value.findAndReplace.length) {
                                                value.findAndReplace.forEach(ele => {
                                                    objErrPageWP.search_and_replace.push({
                                                        'tag': 'p',
                                                        'target_text': ele.old,
                                                        'replace_text': ele.new
                                                    })
                                                })
                                            }
                                            objWP.changes.push(objErrPageWP)
                                        }
                                    });
                                    var configWP = {
                                        method: 'POST',
                                        // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                                        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            // 'User-Agent': 'insomnia/10.1.1',
                                            // 'seocloud-registration-key': keyID
                                        },
                                        data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                                    }

                                    this.axios(configWP)
                                    .then(() => {
                                        console.log('fixes file updated wp')
                                    })
                                    .catch(e => console.log(e.message))
                                }, 4000)
                            })
                            })
                        }, 2000)
                    })

                    this.manualAlt = ''
                }
            }
        },
        submitEditar(payload) {
            //   if(this.$route.name === 'shared-site' && (this.numberPages === 1 || this.numberPages === 2 || this.numberPages === '1' || this.numberPages === '2')) {
            //     this.$refs.sidebar_copilot.hide()
            //   } else {
            //     this.$refs.sidebar_copilot.hide()
            //   }
        },

        // clipboard
        onCopy() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Url copied',
                    icon: 'BellIcon',
                    variant: 'success',
                },
            })

            this.shareCopiedUrl = true
            setTimeout(() => {
                this.shareCopiedUrl = false
            }, 4500)
        },
        onError() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Failed to copy url!',
                    icon: 'BellIcon',
                    variant: 'danger',
                },
            })
        },

        // confirm tab change
        confirmationChangeTab(val, isChangeVisualFixedImg) {
            // console.log(val)
            
            this.$bvModal
            .msgBoxConfirm('You have unsaved changes. Are you sure you want to continue without saving?', {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Apply changes',
                cancelTitle: `Don't apply and continue`,
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                noCloseOnBackdrop: true, // Evita que se cierre al hacer clic fuera
                noCloseOnEsc: false 
            })
            .then((res) => {
                // console.log('then',res)
                if(res === true) {
                    // apply changes
                    if(val === 'generate') {
                        this.loadImgIAGenerated()
                    } else if(val === 'reimagine') {
                        this.loadImgIAGenerated()
                    } else if(val === 'upscale') {
                        this.loadImgIAGenerated()
                    } else if(val === 'remover') {
                        this.loadImgIAGenerated()
                    } else if(val === 'resize') {
                        this.loadImgIAGenerated()
                    } else if(val === 'background') {
                        this.loadImgIAGenerated()
                    } else if(val === 'prompt') {
                        this.loadImgIAGenerated()
                    }
                } else {
                    if(isChangeVisualFixedImg) {
                        if(this.imgValueTrack === 'Original') {
                            this.urlImgToModified = this.objErrImg.initImg
                            this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                        } else if(this.imgValueTrack === 'Original (not found)') {
                            this.isErrorImg = true
                            this.urlImgToModified = this.objErrImg.initImg
                            this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                        } else {
                            this.urlImgToModified = this.objErrImg.img
                            this.setExistImage(this.objErrImg.img)
                        }
                    } else {
                        if(val === 'generate') {
                            this.activeTab = 'generate'
                        } else if(val === 'reimagine') {
                            this.activeTab = 'reimagine'
                        } else if(val === 'upscale') {
                            this.upscaleImage()
                        } else if(val === 'remover') {
                            this.activeTab = 'remover'
                        } else if(val === 'resize') {
                            this.activeTab = 'resize'
                        } else if(val === 'background') {
                            this.activeTab = 'background'
                        } else if(val === 'prompt') {
                            this.activeTab = 'prompt'
                        }
                    }
                }
            })
            .catch(() => {
                // console.log('catch')
                if(isChangeVisualFixedImg) {
                    if(this.imgValueTrack === 'Original') {
                        this.urlImgToModified = this.objErrImg.initImg
                        this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                    } else if(this.imgValueTrack === 'Original (not found)') {
                        this.isErrorImg = true
                        this.urlImgToModified = this.objErrImg.initImg
                        this.setExistImage(this.objErrImg.fixedImg && this.objErrImg.initImg ? this.objErrImg.initImg : this.objErrImg.img)
                    } else {
                        this.urlImgToModified = this.objErrImg.img
                        this.setExistImage(this.objErrImg.img)
                    }
                } else {
                    if(val === 'generate') {
                        this.activeTab = 'generate'
                    } else if(val === 'reimagine') {
                        this.activeTab = 'reimagine'
                    } else if(val === 'upscale') {
                        this.upscaleImage()
                    } else if(val === 'remover') {
                        this.activeTab = 'remover'
                    } else if(val === 'resize') {
                        this.activeTab = 'resize'
                    } else if(val === 'background') {
                        this.activeTab = 'background'
                    } else if(val === 'prompt') {
                        this.activeTab = 'prompt'
                    }
                }
            })
        },

        // confirm close sidebar
        confirmationCloseSidebar() {
            // console.log(val)
            if(this.getStatusTab()) {
                this.$bvModal
                .msgBoxConfirm('You have unsaved changes. Are you sure you want to continue without saving?', {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Save changes',
                    cancelTitle: `Don't apply and continue`,
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                    noCloseOnBackdrop: true, // Evita que se cierre al hacer clic fuera
                    noCloseOnEsc: false 
                })
                .then((res) => {
                    // console.log('then',res)
                    if(res === true) {
                        // apply changes
                        if(this.activeTab === 'generate') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'generate')
                        } else if(this.activeTab === 'reimagine') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'reimagine')
                        } else if(this.activeTab === 'upscale') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'upscale')
                        } else if(this.activeTab === 'remover') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'remover')
                        } else if(this.activeTab === 'resize') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'resize')
                        } else if(this.activeTab === 'background') {
                            // this.loadImgIAGenerated()
                            this.saveImg({fixText: '', idc: 8, oldText: this.objErrImg.content ? this.objErrImg.content : '', idError: this.idErrorSelect, fixTextAlt: '', img: this.objErrImg.content ? this.objErrImg.content : '', idrequest: ''}, 'background')
                        } else if(this.activeTab === 'prompt') {
                            this.closeSidebar()
                            }
                        
                    } else {
                        this.closeSidebar()
                    }
                })
                .catch(() => {
                    // console.log('catch')
                    this.closeSidebar()
                })
            } else {
                this.closeSidebar()    
            }
        },
    }
}
</script>

<style>
.container-dropdown-generate label {
    font-size: 1rem !important;
}
.custom-select-lg {
    font-size: 1rem !important;
    margin-top: 3px;
}
.imgs-ia-container {
    height: calc(100% - 200px) !important;
}
.imgs-ia-container-fixedURL {
    height: calc(100% - 240px) !important;
}
.imgs-ia-container-tab {
    height: 100% !important;
}
.imgs-ia-container img {
    background: #fff !important;
}
.imgs-ia-container-fixedURL img {
    background: #fff !important;
}
.imgs-ia-container-div {
    height: 100% !important;
}
.imgs-ia-container-img {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    height: 100% !important;
}
.modal .modal-footer {
    padding: 5px;
}
.btn-mod-img-generated {
    border: 1px solid #7838ff !important;
}
.upload-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.custom-file-upload {
  display: inline-block;
  /* background-color: #4caf50; */
  /* color: white; */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s;
  width: 100%;
  height: 150px;
  padding: 10% 45%;
  background: #f0f1f7;
  color: #181e38;
}

.custom-file-upload:hover {
  background-color: #c7c8cc;
}

.custom-file-upload-mask {
  display: inline-block;
  /* background-color: #4caf50; */
  /* color: white; */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s;
  width: 100%;
  height: 150px;
  padding: 10% 30%;
  background: #f0f1f7;
  color: #181e38;
}

.custom-file-upload-mask:hover {
  background-color: #c7c8cc;
}

input[type="file"] {
  display: none;
}

.preview-container {
  margin-top: 10px;
  text-align: center;
}

.preview-image {
  width: auto;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.refreshIconSuggest {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 18px;
  height: 20px;
  /* margin:-60px 0 0 -60px; */
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
.before,
.after {
    margin: 0;
}

.before figcaption,
.after figcaption {
    background: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 12px;
    color: #2e3452;
    opacity: 0.8;
    padding: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 100%;
    }

.before figcaption {
    left: 12px;
}

.after figcaption {
    right: 12px;
}
.custom-animated-handle {
    transition: transform 0.2s;
}

.slider-with-animated-handle {
    /* margin: 20px; */
    margin: auto;
    display: block;
}

.slider-with-animated-handle:hover .custom-animated-handle {
    transform: scale(1.2);
}
.btn-group {
    display: flex;
    gap: 4px;
}
.linkURLImg:hover {
    filter: none;
}
/* .btn-tab-images-ia:hover {
    border-bottom: 1.5px solid #ddd !important;
    padding-bottom: 5px !important;
} */
/* .btn-tab-images-ia {
    right: -5.5px !important;
}
.btn-tab-images-ia-active {
    right: -5.5px !important;
} */
.btn-tab-images-ia::after:hover {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 103%;
  height: 3px; /* Grosor del borde */
  background: linear-gradient(90deg, #7838ff 0%, #30CDFF 100%);
}
.btn-tab-images-ia::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 103%;
  height: 3px; /* Grosor del borde */
  background: #ddd !important;
}
.btn-tab-images-ia-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 103%;
  height: 3px; /* Grosor del borde */
  background: linear-gradient(90deg, #7838ff 0%, #30CDFF 100%);
}
.btn-tab-images-ia:hover::after {
  /* content: "";
  position: absolute;
  bottom: 0;
  left: 0; */
  width: 103%;
  height: 3px;
  background: linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
@media (min-width: 1270px) {
    .header-img-ia {
        display: flex !important;
    }
    .left-header-img-ia {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }
    .right-header-img-ia {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    /* tabs boxes */
    .box-image-generate {
        width: 530px;
        max-width: 530px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 265px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-reimagine {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 430px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-upscale {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 330px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-remover {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 5px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-resize {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 5px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-background {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        right: 5px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }

    .box-image-prompt {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        /* right: 430px; */
        left: 13px;
        top: 64px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
}
@media (max-width: 1269px) {
    .header-img-ia {
        display: block !important;
    }
    .left-header-img-ia {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .right-header-img-ia {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .right-header-img-ia .justify-content-end {
        justify-content: left !important; 
    }
    .right-header-img-ia .justify-content-end .btn-group {
        margin-left: 25px;
        display: inline;
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 1269px) and (min-width: 832px) {
    /* tabs boxes */
    .box-image-generate {
        width: 530px;
        max-width: 530px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-reimagine {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 260px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-upscale {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 260px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-remover {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 345px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-resize {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 485px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-background {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 615px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }

    .box-image-prompt {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        /* left: 260px; */
        left: 13px;
        top: 64px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
}
@media (max-width: 831px) {
    /* tabs boxes */
    .box-image-generate {
        max-width: 530px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-reimagine {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-upscale {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-remover {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-resize {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
    .box-image-background {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        left: 20px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }

    .box-image-prompt {
        max-width: 470px;
        position: absolute;
        background: #fff !important;
        padding: 15px;
        border-radius: 15px;
        margin-top: 8px;
        /* left: 20px; */
        left: 13px;
        top: 64px;
        z-index: 15;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border-radius: 10px;
        border: 1px solid #bbbbb9 !important;
    }
}
</style>

<style lang="scss">
#sidebar-right-edit-image {
    width: 96%;
    background: #f0f0f0 !important;
    // max-width: 1750px !important;
    .b-sidebar-body {
        overflow-x: hidden;
    }
}
.btn-dropdown-generate {
    .dropdown-toggle {
        position: absolute;
        right: 0;
        margin-right: 8px;
        border-radius: 50%;
        width: 22px;
        background: #ddd !important;
        border: 0px;
        top: 15px;

        svg {
            position: relative;
            left: -10px;
        }
    }

    .dropdown-menu-right {
        width: 465px !important;
        // top: 0px;
        left: -350px;
        // transform: translate3d(1034px, 59px, 0px);

        .container-dropdown-generate {
            width: 450px !important;
        }
    }
}
</style>