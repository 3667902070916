<template>
  <nav class="menu_testResults">
    <ul v-if="type">
      <li class="back-nav-performance-title">
        <div
          class="cursor-pointer"
          :class="type === 'history' ? 'no-show' : ''"
          @click="updateType('history')"
        >
          <span><feather-icon
            icon="ArrowLeftIcon"
            size="28"
            class="inline-block text-primary"
            style="margin-top:-2px"
          /></span>
        </div>
      </li>
      <li>
        <a
          :class="type === 'history' ? 'active' : ''"
          @click="updateType('history')"
        >
          History
        </a>
      </li>
      <li>
        <a
          :class="type === 'desktop' ? 'active' : ''"
          @click="updateType('desktop')"
        >
          Desktop
        </a>
      </li>
      <li>
        <a
          :class="type === 'mobile' ? 'active' : ''"
          @click="updateType('mobile')"
        >
          Mobile
        </a>
      </li>
    </ul>
  </nav>
</template>
  
  <script>
import { BSkeleton } from "bootstrap-vue";
export default {
  components: {
    BSkeleton,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateType(value) {
      this.$emit("update:type", value);
    },
  },
};
</script>
  
<style>
@media (max-width: 1024px) {
  .back-nav-performance-title {
    display: none !important;
  }
}
</style>