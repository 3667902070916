<template>
  <section class="performance_history_section mb-3">
    <b-row class="m-0">
      <b-col
        md="8"
        class="all_history mb-8"
      >
        <div class="pageScore">
          <div class="d-flex mb-2 container-title-history-perform">
            <span
              v-if="loader"
              class="loader"
            />
            <h3 class="w-50 title-history-perform">
              History
            </h3>
            <div class="w-50 d-flex align-items-center justify-content-end toogle_graph_data">
              <label
                :class="!typeGraph ? 'active': ''"
                class="label_load"
                for="type_graph"
              >Load time</label>
              <b-form-checkbox
                v-model="typeGraph"
                checked="false"
                name="type_graph"
                switch
                inline
                :class="typeGraph ? 'active switch-performance': 'switch-performance'"
                @change="setSeries"
              >
                Performance
              </b-form-checkbox>
            </div>
          </div>
          <p class="info">
            <span style="position: relative; top: -20px;">
            <feather-icon
              icon="CalendarIcon"
              class="inline-block"
            />
            Showing for:
            </span>
            <ul class="datePicker_options">
              <li
                v-for="(item, key) in rangeDateOptions"
                :key="key"
                :class="rangeDate === key ? 'active' : ''"
                @click="updateChart(key)"
              >
                {{ item }}
              </li>
            </ul>
          </p>
          <apex
            ref="chartHistory"
            type="line"
            height="390px"
            width="100%"
            :options="dateChart.options"
            :series="dateChart.series"
            :class="showTooltip ? 'tooltipShowing' : ''"
          />
          <CustomChartTooltip
            v-show="false"
            ref="tooltip"
            :config="tooltipConfig"
          />
        </div>
      </b-col>
      <b-col
        md="4"
        class="flex history_resume mb-8"
      >
        <div
          v-if="!tooltipClick"
          class="content_history_resume w-100"
        >
          <h4>Your most recent score</h4>
          <p class="text-gray-400 mb-0 text-center">
            {{ dateTimeRecentScore }}
          </p>
          <div class="toogle_graph_data mt-2 w-100 mx-auto">
            <label
              :class="!isDesktop ? 'active': ''"
              class="label_load"
              for="type_graph"
            >Mobile</label>
            <b-form-checkbox
              v-model="isDesktop"
              checked="false"
              name="type_graph"
              switch
              inline
              :class="isDesktop ? 'active switch-performance': 'switch-performance'"
              @change="setSeries"
            >
              Desktop
            </b-form-checkbox>
          </div>
          <apex
            v-show="typeGraph"
            type="radialBar"
            height="250px"
            width="250px"
            :options="optionsChart.chartOptions"
            :series="optionsChart.series"
          />
          <h3
            v-show="!typeGraph"
            class="my-20"
            style="font-size:68px;font-weight:bold;color:#1A2F6A;text-align:center"
          >
            {{ loadTimeRecentScore }}
          </h3>
          <p class="text-center mb-1">
            <a class="view-more-details-btn" @click="$emit('changeType', isDesktop ? 'desktop': 'mobile')">
            View More Details
          </a>
          </p>
          <diffDetails :difference="difference" :typeGraph="typeGraph"/>
          <average-information :cacheData="propCache" :isDesktop="isDesktop" :typeGraph="typeGraph" />
        </div>
        <resume-chart
          v-else
          :tooltip-click.sync="tooltipClick"
          :site-key="siteKey"
          @update="updateCurrent"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol, BRow, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import apex from 'vue-apexcharts'
import functions from './performanceFunctions'
import CustomChartTooltip from './Chart/CustomChartTooltip.vue'
import ResumeChart from './Chart/ResumeChart.vue'
import AverageInformation from './AverageInformation.vue'
import diffDetails from './History/diffDetails.vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    apex,
    BFormCheckbox,
    CustomChartTooltip,
    ResumeChart,
    AverageInformation,
    diffDetails,
  },
  props: {
    history: {
      type: Object,
      required: true,
    },
    groupDates: {
      type: Object,
      required: true,
    },
    siteKey: {
      type: String,
      required: true,
    },
    cacheData: {
      type: Object,
      required: true,
      default: () => {},
    },
    rangeFound: {
      type: String,
      default: 'week'
    }
  },
  data() {
    return {
      rangeDate: 'week',
      rangeDateOptions: {
        all: 'All',
        year: 'Last Year',
        month: 'Last Month',
        week: 'Last Week',
      },
      propCache: null,
      loader: false,
      loadTimeRecentScore: '',
      dateTimeRecentScore: '',
      optionsChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 350,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '70%',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  color: '#232C51',
                  fontSize: '44px',
                  show: true,
                },
                value: {
                  color: '#232C51',
                  fontSize: '23px',
                  show: true,
                  formatter(val) {
                    if (val >= 0 && val <= 39) return 'bad'
                    if (val >= 40 && val <= 59) return 'average'
                    if (val >= 60 && val <= 89) return 'good'
                    if (val >= 90) return 'excellent'
                    return ''
                  },
                },
              },
            },

          },
          fill: {
            colors: ['#53D991'],
            opacity: 1,
          },
          stroke: {
            lineCap: 'round',
          },
          labels: [0],
        },
      },
      dateChart: {
        series: [
          {
            name: 'Desktop',
            data: [],
          },
          {
            name: 'Mobile',
            data: [],
          },
        ],
        options: {
          chart: {
            height: 400,
            type: 'line',
            zoom: {
              enabled: true,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const { dataPointIndex } = config
                this.tooltipClick = functions.formatDataToResumeToolTipClick(dataPointIndex, this.history)
              },
            },
          },
          colors: ['#CA34FF', '#30CDFF'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          grid: {
            row: {
              colors: ['#F9F9F9', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: 'datetime',
          },
          legend: {
            show: true,
          },
          markers: {
            size: 5,
            strokeWidth: 1,
          },
        },
      },
      typeGraph: true,
      isDesktop: false,
      average: {},
      difference: null,
      showTooltip: false,
      tooltipClick: null,
      tooltipConfig: {
        locked: true,
        title: '',
        elements: [
          {
            markerColor: '',
            label: '',
            value: '',
            link: '',
          },
        ],
      },
    }
  },
  watch: {
    groupDates() {
      if (this.loader) {
        this.updateChart(this.rangeDate)
        this.updateAverage()
        this.loader = false
      }
    },
  },
  mounted() {
    // Add event listener to the document to detect clicks outside the chart
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeUnmount() {
    // Remove the event listener to prevent memory leaks
    document.removeEventListener('click', this.handleDocumentClick);
  },
  created() {    
    this.dateChart.series[0].data = this.history.performance.desktop
    this.dateChart.series[1].data = this.history.performance.mobile
    this.rangeDate = this.rangeFound
    this.recentScore()
    this.updateAverage()
  },
  methods: {
    handleDocumentClick() {
      this.showTooltip = false
    },
    customSharedTooltip({
      series, seriesIndex, dataPointIndex, w,
    }) {
      const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex]
      const hoverIndexes = w.globals.seriesX.map(seriesX => seriesX.findIndex(xData => xData === hoverXaxis))

      const tooltipElements = []
      hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
        if (hoverIndex >= 0) {
          const keysLink = functions.hoverIndexKeys(dataPointIndex, this.history)
          const element = {
            markerColor: w.globals.markers.colors[seriesEachIndex],
            value: w.globals.yLabelFormatters[0](
              series[seriesEachIndex][hoverIndex],
            ) + w.config.series[seriesEachIndex].data[hoverIndex].label,
            label: w.globals.seriesNames[seriesEachIndex],
            link: this.$router.resolve({
              name: 'performance',
              params: { id: this.siteKey },
              query: { d: keysLink.dKey, m: keysLink.mKey, selected: w.globals.seriesNames[seriesEachIndex] },
            }).href,
            key: w.config.series[seriesEachIndex].data[hoverIndex].key,
          }
          tooltipElements.push(element)
        }
      })
      this.tooltipConfig.title = functions.generateDateString(hoverXaxis)
      this.tooltipConfig.elements = tooltipElements
      this.showTooltip = true
      return this.$refs.tooltip.$el.innerHTML
    },
    setSeries() {
      if (this.typeGraph) {
        this.$refs.chartHistory.updateSeries([{
          data: this.history.performance.desktop,
        }, { data: this.history.performance.mobile }], true, true)
      } else {
        this.$refs.chartHistory.updateSeries([{
          data: this.history.loadTime.desktop,
        }, { data: this.history.loadTime.mobile }], true, true)
      }
      this.recentScore()
      this.updateAverage()
    },
    updateCurrent(event) {
      this.$emit('update', event)
    },
    updateChart(val) {
      this.loader = true
      this.$emit('getData', val)      
      
      this.rangeDate = val
      this.tooltipClick = false
      const mode = this.typeGraph ? 'performance' : 'loadtime'
      console.log(val, mode, this.groupDates[val],this.groupDates);
      
      if (val && val !== 'all') {
        this.$refs.chartHistory.updateSeries([{
          data: this.groupDates[val][mode]?.desktop,
        }, { data: this.groupDates[val][mode]?.mobile }], true, true)
      } else {
        this.setSeries()
      }
    },
    updateAverage() {
      this.propCache = JSON.parse(JSON.stringify(this.cacheData))
    },
    recentScore() {
      let valuePerformance = 0
      let valueLoadtime = 0
      const device = this.isDesktop ? 'desktop' : 'mobile'
      const mode = this.typeGraph ? 'performance' : 'loadTime'

      // Data to chart
      valuePerformance = this.history.performance[device].slice(-1)[0].y
      valueLoadtime = this.history.loadTime[device].slice(-1)[0].y
      // Date & Time Most Recent Result
      this.dateTimeRecentScore = `${functions.generateDateStringNoDay(this.history.loadTime[device].slice(-1)[0].x)} ${functions.generateDateTime(this.history.loadTime[device].slice(-1)[0].x)}`
      // diference in registers
      this.difference = functions.calculateDifference(this.history[mode][device].slice(-2))
      // Assign to performance graph
      this.optionsChart.series = [valuePerformance]
      this.optionsChart.chartOptions.labels[0] = `${valuePerformance}%`
      this.optionsChart.chartOptions.fill.colors[0] = functions.getColorChart(valuePerformance)
      // Assign to loadtime graph or number
      this.loadTimeRecentScore = `${valueLoadtime} s`
      // Prueba tooltip
      this.dateChart.options.tooltip = {
        shared: false,
        intersect: true,
        custom: ({
          series,
          seriesIndex,
          dataPointIndex,
          w,
        }) => this.customSharedTooltip({
          series,
          seriesIndex,
          dataPointIndex,
          w,
        }),
      }
    },
  },
}
</script>

<style>
.toogle_graph_data {
  margin-bottom: 15px;
}
.loader {
  margin-top: 35px;
  position: absolute;
}
@media (max-width: 560px) {
  .loader {
    margin-left: 110px;
    margin-top: 0px !important;
  }
  .__performance-module-seocloud {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .all_history {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pageScore {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .content_history_resume {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .toogle_graph_data {
    width: 100% !important;
    text-align: left !important;
    align-items: start !important;
    justify-content: left !important;
  }
  .title-history-perform {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .container-title-history-perform {
    display: block !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tooltipShowing{
  .apexcharts-tooltip{
    opacity: 1!important;
    pointer-events: all;
  }
}
.view-more-details-btn{
  background: #20b7e7;
  padding: 8px 12px;
  color:white!important;
  text-transform: lowercase;
  border-radius: 8px;
  &:hover{
    opacity: 0.8;
  }
}
</style>
