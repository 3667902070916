/* eslint-disable class-methods-use-this */
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getInfoShare(site, page, key) {
      return new Promise(resolve => {
        axios.get(`https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/share/${site}?key=${key}&page=${page}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    getPages(url) {
      return new Promise(resolve => {
        axios.post('https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/sites/pro-pages', { url, listAll: true })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    takeScreenshotNow(website, comment) {
      return new Promise(resolve => {
        axios.post('https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/take-screenshot', { website, comment })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    getScreenshots(site) {
      return new Promise(resolve => {
        axios.get(`https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/history/${site}`)
          .then(response => {
            resolve(response.data.result)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
  },
}