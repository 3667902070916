<template>
  <b-row>
    <b-col v-if="times.first_contentful_paint">
      <StaticsCard
        key="first_contentful_paint"
        :statistic="times.first_contentful_paint"
        statistic-title="First Contentful Paint (FCP)"
        class="shadow-none"
        no-icon
      />
    </b-col>
    <b-col v-if="times.largest_contentful_paint">
      <StaticsCard
        key="largest_contentful_paint"
        :statistic="times.largest_contentful_paint"
        statistic-title="Largest Contentful Paint (LCP)"
        class="shadow-none"
        no-icon
      />
    </b-col>
    <b-col v-if="times.total_blocking_time">
      <StaticsCard
        key="total_blocking_time"
        :statistic="times.total_blocking_time"
        statistic-title="Total Blocking Time"
        class="shadow-none"
        no-icon
      />
    </b-col>
    <b-col v-if="times.cumulative_layout_shift">
      <StaticsCard
        key="cumulative_layout_shift"
        :statistic="times.cumulative_layout_shift"
        statistic-title="Cumulative Layout Shift"
        class="shadow-none"
        no-icon
      />
    </b-col>
  </b-row>
</template>

<script>
import StaticsCard from "./Chart/StaticsCard.vue";
import { BCol, BRow, BSkeleton, BSkeletonImg } from "bootstrap-vue";
export default {
  components: {
    StaticsCard,
    BCol,
    BRow,
  },
  name: "AdditionalStats",
  props: {
    times: {
      type: Object,
      default: () => {},
      required: true,
    },
    scores: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>